import {
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  Button,
  Container,
  Flex,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react"
import { FaSync, FaUpload } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { modulos } from "../../../store/items";
import { ModuloMap } from "../types";

export function ModuloCompleto({reload, relatorio_id, inspecao_id, modulo, referencias, inspecao, relatorio }: any) {
 const navigate = useNavigate()
  let modulosPerguntasRestantes = {} as { [key: string]: number }

  Object.keys(modulos).forEach(modulo => {
    let referencias = modulos[modulo]
    const perguntas = inspecao.item?.filter((item: any) => referencias.includes(item.referencia) && !item.resposta)
    modulosPerguntasRestantes[modulo] = 100 - Math.floor(perguntas.length / referencias.length * 100)
  })

  const isReady =  relatorio.inspecao.reduce((accumulator: any, thisInspecao: any) => {
    
    const response = thisInspecao.item.reduce((acc: any, item: any) => {
      acc = item.resposta > 0 ? acc : false
      return acc
    }, true) 

    accumulator = response ? accumulator : false
    return accumulator
  }, true)

  return (
    <Container mt={8}>
 {isReady && (
        <>
        
          <Link to={`/inspecao/${relatorio_id}/${inspecao_id}/finalizar`}>
            <Button my="5" w="full" mt="2" colorScheme={"linkedin"} leftIcon={<FaUpload />} >
              SINCRONIZAR
            </Button>
          </Link>
        </>
      )}

      <Heading textAlign={"center"}>Completo!</Heading>
      <Text color={'gray.500'} fontSize={'lg'} mb={3}>
        O módulo <b>{modulo?.toLowerCase()}</b> está devidamente preenchido!
      </Text>
      <Flex direction="column" gap={3}>
        {Object.keys(ModuloMap).map((thisModulo, i) => {
          const percent = modulosPerguntasRestantes[ModuloMap[thisModulo!]]
          const color = !percent ? "red" : percent < 100 ? "yellow" : "green"
          return (
              <Button key={`modulos-m2-${i}`}  w="full" variant="outline" columnGap={2} colorScheme={color} display="flex" alignItems={"center"}
                onClick={() => {
                  navigate(`/inspecao/${relatorio_id}/${inspecao_id}/${thisModulo}`)
                  reload()
                }}
              >
                <Text fontSize={12} >{ModuloMap[thisModulo!]}</Text>
                <CircularProgress size={"30px"} value={percent} color={useColorModeValue(`${color}.400`, `${color}.300`)}>
                  <CircularProgressLabel color={useColorModeValue(`${color}.700`, `${color}.200`)}>{percent}%</CircularProgressLabel>
                </CircularProgress>
              </Button>
          )
        })}
      </Flex>

      {isReady && (
        <>
          <Text color="gray.400" mt="5">
            Essa opção irá encerrar a inspeção, sincronizando os dados com o servidor e gerando o relatório.
          </Text>
          <Link to={`/inspecao/${relatorio_id}/${inspecao_id}/finalizar`}>
            <Button w="full" mt="2" colorScheme={"linkedin"} leftIcon={<FaUpload />} >
              SINCRONIZAR
            </Button>
          </Link>
        </>
      )}



    </Container>
  )
}