import React from "react"
import {
  Flex,
  Text,
  Stack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  PlusSquareIcon
} from '@chakra-ui/icons';
import { FaWifi } from "react-icons/fa"
import { Link } from 'react-router-dom';

export function AbrirModalImportarRelatorio() {
  return (
    <Link to={`/importar-relatorio`}>
      <Stack
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue("green.50", "green.900"), 
        }}
        direction={'row'} align={'center'}
      >
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={"green.100"}
        >
          <Icon as={PlusSquareIcon} color={'green.500'} w={5} h={5} />
        </Flex>
          <Flex justifyContent={"center"} alignItems="center">
            <Text  fontWeight={600}>Importar novo relatório</Text>
            <Icon as={FaWifi} ml={1} color={"green.200"} />
          </Flex>
      </Stack>
    </Link>
  )
}