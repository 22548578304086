import { Button } from "./Button";
import React from "react"

export function LoadMore({state, onClick}: {state:boolean, onClick: any}) {
  return <Button
    state={state}
    colorScheme="blue"
    variant="outline"
    label={"Carregar mais"}
    onClick={onClick}
    w="100%"
  />
}