import React, { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom";
import { AskForPermission } from "../components/AskForPermission";
import { FullPageLoading } from "../components/FullPageLoading";
import { NavTopBar } from "../components/Nav";
import { useAuth } from "../context/AuthContext";
import { NavProvider } from "../context/NavigationContext";
import { MainPage } from "../pages/Main";
import { api } from "../services/api";
import { minutes, seconds } from "../utils";
import { PrivateRoutes } from "./private.routes";
import { PublicRoutes } from "./public.routes";

let globalGeolocationPermission: PermissionStatus

export function MainRoutes() {
  /*
  const [firstLoading, setFirstLoading] = useState(true);
  const [appAllowed, setAppAllowed] = useState(false);

  function checkLocation () {
    navigator.permissions.query({ name: 'geolocation' })
    .then(geolocationPermission => {
      setAppAllowed(geolocationPermission.state === 'granted');
    })
  }

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' })
    .then(geolocationPermission => {
      globalGeolocationPermission = geolocationPermission;
      setAppAllowed(geolocationPermission.state === 'granted');

      geolocationPermission.addEventListener('change', (event) => {
        setAppAllowed((event.currentTarget as PermissionStatus).state === "granted");
      })
    })

    setFirstLoading(false)

    const interval = setInterval(checkLocation, minutes(1))

    return () => {
      clearInterval(interval);
      globalGeolocationPermission.removeEventListener("change", () => {} )
    }

  }, []);

  function postLocation() {
    navigator.geolocation.getCurrentPosition(async result => {

      await api.post('/update-location', {
        latitude: result.coords.latitude,
        longitude: result.coords.longitude,
        accuracy: result.coords.accuracy,
        timestamp: result.timestamp,
      })
    })
  }
  useEffect(() => {
    setTimeout(postLocation, seconds(30));
    const effect = setInterval(postLocation, minutes(10))
    return () => {
      clearInterval(effect);
    }

  }, [])

*/
  const { signed, loading } = useAuth()

  if (loading) return <FullPageLoading />
  if (!signed) {
    return <PublicRoutes />
  }
  /*
  if (firstLoading) {
    return <FullPageLoading />
  }
  if (!appAllowed) {
    return <AskForPermission checkLocation={checkLocation} />
  }*/
  return <>
    <NavProvider>
      <NavTopBar />
      <PrivateRoutes />
      <div style={{padding: 20}}></div>
    </NavProvider>
  </>
}