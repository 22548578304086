
import { useQuery } from "react-query"
import { getEquipamentos } from "../services/equipamentos";

export function useEquipamentos({ page, pageSize }: { page: number, pageSize: number }) {
  let skip = 0;

  if (page > 1) {
    skip = (page - 1) * pageSize;
  }

  return useQuery(
    ["equipamentos", page],
    () => getEquipamentos({ skip, take: pageSize }),
    {
      staleTime: 1000 * 6
    }
  )
}
