import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "../components/PageNotFound";
import { AdminPaginaInicial } from "../pages/Admin";
import { AdminListaColaboradoresCadastro } from "../pages/Admin/pages/CadastrarColaboradores";
import { AdminCadastrarEquipamento } from "../pages/Admin/pages/CadastrarEquipamento";
import { CadastrarRelatorio } from "../pages/Admin/pages/CadastrarRelatorio";
import { AdminListaColaboradores } from "../pages/Admin/pages/ListaColaboradores";
import { AdminListaEquipamentos } from "../pages/Admin/pages/ListaEquipamentos";
import { OkCadastroEquipamento } from "../pages/Admin/pages/OkCadastroEquipamento";
import { AdminPainel } from "../pages/Admin/pages/Painel";
import { AdminPageSuccess } from "../pages/Admin/pages/Success";
import { EditarOS } from "../pages/EditarOS";
import { EditarRelatorio } from "../pages/EditarRelatorio";
import { Finalizar } from "../pages/Finalizar";
import { ImportarBackup } from "../pages/ImportarBackup";
import { ImportarRelatorio } from "../pages/ImportarRelatorio";
import { Inspecao } from "../pages/Inspecao";
import { PageAdicionarDescricao } from "../pages/Inspecao/pages/PageAdicionarDescricao";
import { PageConformeComFoto } from "../pages/Inspecao/pages/PageConformeComFoto";
import { PageNaoConforme } from "../pages/Inspecao/pages/PageNaoConforme";
import { ListarRelatorio } from "../pages/ListarRelatorio";
import { PainelDeControle } from "../pages/PainelDeControle";
import { Profile } from "../pages/Profile";
import { Relatorio } from "../pages/Relatorio";
import { Settings } from "../pages/Settings";
import { Success } from "../pages/Success";

export function PrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PainelDeControle />} />
      <Route path="/admin" element={<AdminPaginaInicial />} />
      <Route path="/admin/painel" element={<AdminPainel />} />
      <Route path="/admin/painel/relatorios" element={<CadastrarRelatorio />} />
      <Route path="/admin/painel/relatorios/success" element={<AdminPageSuccess />} />
      <Route path="/admin/painel/equipamentos" element={<AdminListaEquipamentos />} />
      <Route path="/admin/painel/equipamentos/criar" element={<AdminCadastrarEquipamento />} />
      <Route path="/admin/painel/equipamentos/criar/ok" element={<OkCadastroEquipamento />} />
      <Route path="/admin/painel/colaboradores" element={<AdminListaColaboradores />} />
      <Route path="/admin/painel/colaboradores/cadastro" element={<AdminListaColaboradoresCadastro />} />
      <Route path="/sincronizado" element={<Success />} />
      <Route path="/importar-relatorio" element={<ImportarRelatorio />} />
      <Route path="/importar-rascunhos" element={<ImportarBackup />} />
      <Route path="/relatorio/:relatorio_id" element={<Relatorio />} />
      <Route path="/relatorio/:relatorio_id/editar" element={<EditarRelatorio />} />
      <Route path="/relatorio/:relatorio_id/editar/os" element={<EditarOS />} />
      <Route path="/inspecao/:relatorio_id/:inspecao_id/finalizar" element={<Finalizar />} />
      <Route path="/inspecao/:relatorio_id/:inspecao_id/:modulo_id" element={<Inspecao />} />
      <Route path="/inspecao/:relatorio_id/:inspecao_id/:modulo_id/nf/:referencia" element={<PageNaoConforme />} />
      <Route path="/inspecao/:relatorio_id/:inspecao_id/:modulo_id/conforme-comfoto/:referencia" element={<PageConformeComFoto />} />
      <Route path="/inspecao/:relatorio_id/:inspecao_id/:modulo_id/add-description/:referencia" element={<PageAdicionarDescricao />} />
      <Route path="/profile/:user_id" element={<Profile />} />
      <Route path="/imprimir/" element={<ListarRelatorio />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/*" element={<PageNotFound />} />
      
    </Routes>
  )

}