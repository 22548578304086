import { BuildQuery } from '../../utils';
import { api } from './../api';

export interface Equipamento {
  
  "id": string
  "name": string
  "endereco"?: string,
  "bairro"?: string
	
}
export async function getEquipamentos({ skip, take }: { skip: number, take: number }) {
  const { data } = await api.get<Equipamento[]>(`/equipamento/` + BuildQuery({skip, take }));
  return data
}
