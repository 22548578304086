import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  PopoverAnchor,
  InputRightAddon,
  Button,
  Grid
} from '@chakra-ui/react'
import React from 'react'
import { FaList } from 'react-icons/fa'

const acoes = [
  "ORÇ - Orçamento REP. e MOD.",
  "ITP - Pedido de peças",
  "CC - Comunicação ao cliente",
  "DIR - Manutenção dirigida",
  "COR - Corrigido"
]
export function PopoverAcao({ setAcao }: any) {
  const initRef = React.useRef<any>()
  return (
    <Popover

      initialFocusRef={initRef}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger >
            <InputRightAddon children={<FaList />} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Ações</PopoverHeader>
            <PopoverBody>
              <Grid gap={4} maxH="40vh" overflowY={"scroll"}>
                {acoes?.map((item, i) => (
                  <Button
                    key={`responsabilidade-button-form-${i}`}
                    ref={initRef}
                    onClick={() => {
                      setAcao(item);
                      onClose();
                    }}>
                    {item}
                  </Button>
                ))}

              </Grid>
            </PopoverBody>
          </PopoverContent>
        </>
      )}

    </Popover>

  )
}