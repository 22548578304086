import React, { useEffect } from "react"
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Button,
  HStack,
  useColorModeValue,
  VStack,
  Image,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { CheckIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { useLiveQuery } from "dexie-react-hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../../store";
import { FullPageLoading } from "../../components/FullPageLoading";
import { useNotify } from "../../hooks/useNotify";
import { api } from "../../services/api";

export function Finalizar() {
  const { relatorio_id } = useParams()

  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [heroOK, setHeroOK] = React.useState(false)
  const [osOK, setosOK] = React.useState(false)
  const [itemOK, setItemOK] = React.useState(true)
  const [NFOK, setNFOK] = React.useState(true)
  const [equipamento_list, setequipamento_list] = React.useState([] as string[])
  const [nf_list, setnf_list] = React.useState([] as string[])
  const [item_equipamento_list, setitem_equipamento_list] = React.useState([] as string[])
  const [everyone_ok, set_everyone_ok] = React.useState(false);

  const relatorio = useLiveQuery(async () => {
    return await db.relatorio.get(relatorio_id!)
  })

  useEffect(() => {

    if (relatorio?.hero?.length) { setHeroOK(true) }

    let arr_nf: string[] = []
    let arr_item_equipamento_list: string[] = []
    const ok = relatorio?.inspecao?.reduce((value, inspecao) => {
      let resposta = inspecao.item?.reduce((acc, item) => {

        acc = (!(typeof item.resposta === "number" && item.resposta > 0)) ? false : acc

        if (item.resposta === 2) {
          if (!item.anexo) {
            setNFOK(false)
            arr_nf.push(`${inspecao.equipamento_id} - ${item.referencia} - ${item.pergunta}`)
          }
        }
        return acc
      }, true)
      if (!resposta) {
        arr_item_equipamento_list.push(inspecao.equipamento_id)
        setItemOK(false)
      }

      value = inspecao?.OS?.length ? value : false
      return value
    }, true)
    setnf_list(arr_nf)
    setitem_equipamento_list(arr_item_equipamento_list)

    if (!ok) {
      const result = relatorio?.inspecao?.reduce((value, inspecao) => {
        if (!inspecao?.OS?.length) {
          value.push(inspecao?.equipamento_id)
        }
        return value
      }, [] as string[])

      setequipamento_list(result!)
    }
    setosOK(ok!);


  }, [relatorio])

  if (!relatorio) return <FullPageLoading />


  async function sincronizar() {

    if (!relatorio)
      return useNotify({ message: { title: "Falha na operação", description: "Não consegui resgatar as informações necessárias." } })

    try {
      setLoading(true)

      const response = await api.post("/relatorio/submissao", {
        relatorio_id: relatorio.id,
        texto: JSON.stringify(relatorio),
        status: 10
      })

      if (response.status !== 200) {
        throw new Error("Não foi possível sincronizar o rascunho")
      }

      if (response.data?.length) {
        await db.relatorio.delete(relatorio_id!);
        navigate("/sincronizado")
      }
      //aq
      
    } catch (error: any) {
      useNotify({
        type: "error",
        message: {
          title: "Falha na operação",
          description: error?.message || "Não foi possível sincronizar o rascunho"
        }
      })
    }
    finally {
      setLoading(false)
    }


  }

  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading>
          Relatório finalizado
        </Heading>
        <Text color={useColorModeValue('gray.600', 'gray.300')} fontSize={'xl'}>
          Última etapa para sincronização do seu relatório!
        </Text>

        <Box
          mt="5"
          rounded={'2xl'}
          boxShadow={'dark-lg'}
          borderRadius={15}
        >

          <Image width={["100%", "300px"]} src={relatorio?.hero} borderRadius={15} />
        </Box>

      </Stack>

      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={1} spacing={4}>

          <HStack align={'top'}>
            <Box color={heroOK ? 'green.400' : 'red.400'} px={2}>
              <Icon as={heroOK ? CheckIcon : NotAllowedIcon} />
            </Box>
            <VStack align={'start'}>
              <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.300')}>Foto do condomínio</Text>
            </VStack>
          </HStack>

          <HStack align={'top'}>
            <Box color={osOK ? 'green.400' : 'red.400'} px={2}>
              <Icon as={osOK ? CheckIcon : NotAllowedIcon} />
            </Box>
            <VStack align={'start'}>
              <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.300')}>Equipamentos com OS</Text>
            </VStack>

          </HStack>

          <Link to={`/relatorio/${relatorio?.id}/editar/os`}>
            {
              !osOK && <Grid templateColumns={"repeat(3, 1fr)"} gap={2} mt="3">
                {!osOK && equipamento_list?.map((equipamento) => {
                  return (
                    <GridItem key={`falta-os-${equipamento}`} px="3" py="2" border={"1px"} borderColor="gray.300" borderRadius={10}>
                      <Text align="center" color="gray.500" key={`falta-os-${equipamento}`}>
                        {equipamento}
                      </Text>
                    </GridItem>
                  )
                })}
              </Grid>
            }

            {!osOK && <Text color="red.200">Faltam preencher OS dos equipamentos acima</Text>}

          </Link>


          <HStack align={'top'}>
            <Box color={itemOK ? 'green.400' : 'red.400'} px={2}>
              <Icon as={itemOK ? CheckIcon : NotAllowedIcon} />
            </Box>
            <VStack align={'start'}>
              <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.300')}>Respostas completas</Text>
            </VStack>

          </HStack>


          <Link to={`/relatorio/${relatorio?.id}`}>
            {
              !itemOK && <Grid templateColumns={"repeat(3, 1fr)"} gap={2} mt="3">
                {!itemOK && item_equipamento_list?.map((equipamento) => {
                  return (
                    <GridItem key={`falta-os-${equipamento}`} px="3" py="2" border={"1px"} borderColor="gray.300" borderRadius={10}>
                      <Text align="center" color="gray.500" key={`falta-item-${equipamento}`}>
                        {equipamento}
                      </Text>
                    </GridItem>
                  )
                })}
              </Grid>
            }

            {!itemOK && <Text color="red.200">Faltam preencher itens dos equipamentos acima</Text>}

          </Link>


          <HStack align={'top'}>
            <Box color={NFOK ? 'green.400' : 'red.400'} px={2}>
              <Icon as={NFOK ? CheckIcon : NotAllowedIcon} />
            </Box>
            <VStack align={'start'}>
              <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.300')}>Não conformidades com foto</Text>
            </VStack>

          </HStack>

          {!NFOK && <Grid templateColumns={"repeat(1, 1fr)"} gap={2} mt="3">
            {!NFOK && nf_list?.map((equipamento) => {
              return (
                <GridItem key={`falta-os-${equipamento}`} px="3" py="2" border={"1px"} borderColor="gray.300" borderRadius={10}>
                  <Text align="center" color="gray.500" key={`falta-os-${equipamento}`}>
                    {equipamento}
                  </Text>
                </GridItem>
              )
            })}
          </Grid>
          }

          {(NFOK && itemOK && osOK && heroOK) && (
            <Button colorScheme="green" onClick={sincronizar}>
              FINALIZAR
            </Button>
          )}

        </SimpleGrid>
      </Container>
    </Box>
  );
}