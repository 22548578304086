
import { Avatar, Box, Container, Flex, Badge, Text, Button, Divider } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { config } from "../../../config/config"
import { useAuth, User } from "../../../context/AuthContext"
import { getAllUser } from "../../../services/user"


export function AdminListaColaboradores() {
  const { user } = useAuth()

  const [users, setUsers] = useState<User[]>([])
  const [reload, setReload] = useState(false)



  async function handleEffect() {
    const { data } = await getAllUser()
    setUsers(data)
  }
  useEffect(() => {

    handleEffect()

  }, [reload])


  if (user?.privilege! < 9) return <>Permissão insuficiente</>


  return (
    <Container maxW={'5xl'} py={8}>
      <Link to={`/admin/painel/colaboradores/cadastro`}>
      <Button
        w={`full`}
        colorScheme="teal"
        variant="outline"
        size={`sm`}
      >
        Cadastrar novo usuário
      </Button>
      </Link>
   
      <Divider my={4} />
      <Box>
        {users.map(user => (
          <Flex key={user.id} w={`full`} mb={3} alignItems={`center`} justifyContent={`space-between`}>
            <Flex alignItems={`center`}>
              <Avatar
                src={`${config.s3bucket}/user-${user.id}`}
                name={user?.name}
              />
              <Box ml={2}>

                <Text mb={0}>
                  {user?.name}
                </Text>
                <Text mb={0} fontSize={`xs`}>
                  {user?.email}
                </Text>

               

              </Box>
            </Flex>

            <Flex>

              <Badge px={2} py={1} colorScheme={`green`}>ATIVO</Badge>
            </Flex>
          </Flex>
        ))}
      </Box>


    </Container>
  )
}