import { UseToastOptions } from '@chakra-ui/react';
import { toast } from '../components/Notify';

interface useNotifyProps extends UseToastOptions {
  message?: {
    title: string;
    description: string;
  };
  type?: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
  error?: any;
}

export function useNotify({
  duration,
  error,
  message,
  type,
  ...props
}: useNotifyProps) {
  if (error) {
    if (error.code === "ERR_NETWORK") {
      toast({
        position: "top",
        title: `Comunicado`,
        description: `Não foi possível estabelecer conexão com o servidor. Cheque se você está conectado`,
        status: 'info',
        duration: duration || 4000,
        isClosable: true,
      });
    }
    else {
      toast({
        position: "top",
        title: `Erro ${error?.response?.data?.code || 400}.`,
        description: error?.response?.data?.message?.substring(0, 80) || 'Falha na requisição.',
        status: 'error',
        duration: duration || 5000,
        isClosable: true,
      });
    }
   
  } else {
    toast({
      position: "top",
      title: message?.title || '',
      description: message?.description || '',
      status: type,
      duration: duration || 5000,
      isClosable: true,
      ...props,
    });
  }
}