
import {
  Button,
  InputGroup,
  Image,
  Flex,
  Text,
  Box,
  Input,
  Textarea,
  InputRightAddon,
  Container,
  Heading
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNotify } from '../../../hooks/useNotify'
import { Compress } from '../../../utils'
import defaultImage from "../../../assets/uploadImage.png"
import { FaList } from 'react-icons/fa'
import { PopoverResponsabilidade } from '../components/popover/Responsabilidade'
import { PopoverAcao } from '../components/popover/Acao'
import { db } from '../../../store'
import { useLiveQuery } from 'dexie-react-hooks'
import { useNavigate, useParams } from 'react-router-dom'

export function PageConformeComFoto() {

  const navigate = useNavigate()
  const { relatorio_id, inspecao_id, referencia, modulo_id } = useParams()

  const [descricao, setDescricao] = useState("")
  const [acao, setAcao] = useState("")
  const [responsabilidade, setResponsabilidade] = useState("")
  const [observacao, setObservacao] = useState("")
  const [tempImage, setTempImage] = useState("")

  const relatorio = useLiveQuery(async () => {
    return await db.relatorio.get(relatorio_id!)
  })

  const inspecao = relatorio?.inspecao.find(inspecao => inspecao.id === inspecao_id)
  async function handleRespostaNaoConforme() {
    if (!inspecao) return 

    const itemIndice = inspecao.item?.findIndex(item => item.referencia === referencia)
    const inspecaoIndice = relatorio?.inspecao.findIndex(thisinspecao => thisinspecao.id === inspecao.id);


    if (itemIndice === -1 || inspecaoIndice === -1) {
      return alert("Algo de errado aconteceu");
    }

    const toUpdate = {} as any;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.resposta`] = 1;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.descricao`] = descricao || null;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.anexo`] = tempImage || null;

    await db.relatorio.update(relatorio!.id, toUpdate)
    navigate(`/inspecao/${relatorio!.id}/${inspecao.id}/${modulo_id}`)
  }

  
  async function handleChangeHeroImage(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return useNotify({ icon: "error", description: "Falha ao trocar imagem." });
    }
    const hero = await Compress(file)
    setTempImage(hero)
  }


  return (
    <Container py={8}>
      <Heading mb="3">{referencia}</Heading>
      <Box mb={3}>
        <label>Descrição <Text display="inline" color="red.400">*</Text></label>
        <InputGroup mt="1">
          <Input autoFocus value={descricao} onChange={(e) => setDescricao(e.currentTarget.value)} />
          <InputRightAddon children={<FaList />} />
        </InputGroup>
      </Box>

      <label htmlFor='file' style={{ width: 60, height: 60 }}>
        <Flex
          w={"60px"}
          h={"60px"}
          p={tempImage ? 0 : 2}
          background={"gray.200"}
          borderRadius={10}
        >
          <Image
            w={"100%"}
            h={"100%"}
            borderRadius={10}
            src={tempImage || defaultImage}
            objectFit={'fill'}
          />
        </Flex>
      </label>
      <input
        type={"file"}
        name="file"
        id="file"
        accept="image/*"
        style={{
          position: "absolute",
          top: "-1000px"
        }}
        onChange={handleChangeHeroImage}
      />

      <Button disabled={!tempImage?.length || !descricao} mt="3" colorScheme='teal' w="full" onClick={() => {

        if (!tempImage?.length) {
          return useNotify({ type: "info", description: "Por favor, selecione uma imagem." });
        }
        if (!descricao) {
          return useNotify({ type: "info", description: "Por favor, descreva o motivo da não conformidade" });
        }

        handleRespostaNaoConforme()

      }}>
        Confirmar
      </Button>
    </Container>


  )

}