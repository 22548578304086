import React from "react"
import {
  useColorModeValue,
  Text,
  useDisclosure,
  Box
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

type NavLinkProps = {
  label: string;
  href: string;
  icon: any;
}
export const NavLink = ({ item }: { item: NavLinkProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
  <Link to={item.href} onClick={isOpen ? onClose : onOpen}>
    <Box
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}

      alignItems="center"
      display="flex"
    >
      {item.icon}
      <Text ml={2}>{item.label}</Text>
    </Box>
  </Link>
  )
}

