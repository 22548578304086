

interface ItemProps {
  "REFERENCIA": string
  "PERGUNTA DO TECNICO": string
  "PERGUNTA DE EXIBIÇÃO": string
  "MÓDULO": string
  "SUB MODULO": string
  "CATEGORIA SUBMODULO": string
}

export const items: ItemProps[] = [
  {
    "REFERENCIA": "1.1.1",
    "PERGUNTA DO TECNICO": "FIXAÇÃO DA ABA DE SOLEIRA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "ABA de SELEIRA",
    "CATEGORIA SUBMODULO": "-"
  },
  {
    "REFERENCIA": "1.1.2",
    "PERGUNTA DO TECNICO": "MEDIDA DA ABA DE SOLEIRA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "ABA de SELEIRA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.2.1",
    "PERGUNTA DO TECNICO": "FIXAÇÃO - AMORTECEDORES",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "AMORTECEDORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.2.2",
    "PERGUNTA DO TECNICO": "VENCIMENTO DO BUFFER",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "AMORTECEDORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.2.3",
    "PERGUNTA DO TECNICO": "NÍVEL DO ÓLEO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "AMORTECEDORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.2.4",
    "PERGUNTA DO TECNICO": "CONTATO ELETRICO DOS AMORT",
    "PERGUNTA DE EXIBIÇÃO": "CONTATO ELETRICO DOS AMORTECEDOR",
    "MÓDULO": "POÇO",
    "SUB MODULO": "AMORTECEDORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.3.1",
    "PERGUNTA DO TECNICO": "CONTATO ELET POLIA TENS",
    "PERGUNTA DE EXIBIÇÃO": "CONTATO ELÉTRICO DA  POLIA TENSORA",
    "MÓDULO": "POÇO",
    "SUB MODULO": "POLIA TENSORA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.3.2",
    "PERGUNTA DO TECNICO": "ALTURA POLIA TENSORA LPT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "POLIA TENSORA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.4.1",
    "PERGUNTA DO TECNICO": "LCAB ANOTAR MEDIDA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "MEDIDAS INFERIORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.4.2",
    "PERGUNTA DO TECNICO": "LCP ANOTAR MEDIDA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "MEDIDAS INFERIORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.5.1",
    "PERGUNTA DO TECNICO": "LIMPEZA APARELHO DE SEG INF",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "APARELHO DE SEG INF",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.5.2",
    "PERGUNTA DO TECNICO": "AJUSTE APARELHO DE SEG INF",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "APARELHO DE SEG INF",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.5.3.1",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO  GRS AP SEG",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "GRS APARELHO DE SEG INF",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.6.1",
    "PERGUNTA DO TECNICO": "LIMPEZ POLIA DE COMPENSAÇÃO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "POLIA DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.6.2",
    "PERGUNTA DO TECNICO": "CONTATO ELET POLIA COMPENS",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "POLIA DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.6.3",
    "PERGUNTA DO TECNICO": "FIXAÇÃO POLIA DE COMPENS",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "POLIA DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.6.4",
    "PERGUNTA DO TECNICO": "INTEGRIDADE CABO POLIA COMP",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "POLIA DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.7.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE CORRENTE COMP",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CORRENTE DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.7.2",
    "PERGUNTA DO TECNICO": "FIXAÇÃO CORRENTE COMP",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CORRENTE DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.7.3",
    "PERGUNTA DO TECNICO": "INTEGRIDAD BATENTES COR COMP",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CORRENTE DE COMPENSAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.8.1",
    "PERGUNTA DO TECNICO": "PINAGEM CORREDIÇA INFERRIOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CORREDIÇA INFERRIORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.8.2",
    "PERGUNTA DO TECNICO": "FIXAÇÃO CORREDIÇA INFERRIOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CORREDIÇA INFERRIORES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.9.1",
    "PERGUNTA DO TECNICO": "ESCADA DE ACESSO AO POÇO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.9.2",
    "PERGUNTA DO TECNICO": "PINTURA DO PARALELEPÍPEDO DE SEGURANÇA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "1.9.3",
    "PERGUNTA DO TECNICO": "BOTOEIRA DE EMERGÊNCIA (STOP)",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "POÇO",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.1",
    "PERGUNTA DO TECNICO": "ILUMINAÇÃO DE EMERGÊNCIA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.2",
    "PERGUNTA DO TECNICO": "INTERCOMUNICADOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.3",
    "PERGUNTA DO TECNICO": "ALARME",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.4",
    "PERGUNTA DO TECNICO": "PAINEL DE OPERAÇÃO DE CABINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.5",
    "PERGUNTA DO TECNICO": "INDICADOR DE POSIÇÃO DE CABINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.6",
    "PERGUNTA DO TECNICO": "RÉGUA DE SEGURANÇA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.7",
    "PERGUNTA DO TECNICO": "PORTAS DE CABINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "2.8",
    "PERGUNTA DO TECNICO": "ILUMINAÇÃO DE CABINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA INTERNA",
    "SUB MODULO": "CABINA INTERNA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.1",
    "PERGUNTA DO TECNICO": "PORTA DE ACESSO A CASA DE MÁQUINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.2",
    "PERGUNTA DO TECNICO": "ILUMINAÇÃO DA CASA DE MÁQUINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.3",
    "PERGUNTA DO TECNICO": "PISO/PAREDES/JANELAS DA CASA DE MÁQUINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.4",
    "PERGUNTA DO TECNICO": "GANCHOS",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.5",
    "PERGUNTA DO TECNICO": "EXTINTOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.6",
    "PERGUNTA DO TECNICO": "ALÇAPÃO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CONDIÇÕES GERAIS",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.7",
    "PERGUNTA DO TECNICO": "CONDICOES GERAIS QUADRO DE FORÇA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE FORÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.8",
    "PERGUNTA DO TECNICO": "CONEXÕES/FIAÇÕES/ATERRAMENTO  QUADRO DE FORÇA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE FORÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.9",
    "PERGUNTA DO TECNICO": "CHAVE DE FORÇA / FUZIVEIS  QUADRO DE FORÇA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE FORÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.10",
    "PERGUNTA DO TECNICO": "CHAVE JJUNTOR E IDR  QUADRO DE FORÇA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE FORÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.11",
    "PERGUNTA DO TECNICO": "CONDICOES GERAIS  QUADRO DE COMANDO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE COMANDO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.12",
    "PERGUNTA DO TECNICO": "CONEXÕES/FIAÇÕES/ATERRAMENTO QUADRO DE COMANDO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE COMANDO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.0.0.13",
    "PERGUNTA DO TECNICO": "CHAVE DE FORÇA / FUZIVEIS QUADRO DE COMANDO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "QUADRO DE COMANDO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.1.1.1",
    "PERGUNTA DO TECNICO": "INTEGRIDAD CANAIS POLIA TRAÇ",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "POLIA DE TRAÇÃO"
  },
  {
    "REFERENCIA": "3.1.2.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE PROTEÇÃO POLIA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "PROTEÇÃO DA POLIA"
  },
  {
    "REFERENCIA": "3.1.3.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE RETENT/JUNTA/VISO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "RETENTOR/ JUNTA / VISOR"
  },
  {
    "REFERENCIA": "3.1.4",
    "PERGUNTA DO TECNICO": "VALIDADE DO ÓLEO REDUTOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "RETENTOR/ JUNTA / VISOR"
  },
  {
    "REFERENCIA": "3.1.5.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE COROA MQ TRAÇÃO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "COROA DA MAQUINA DE TRAÇÃO"
  },
  {
    "REFERENCIA": "3.1.6.1",
    "PERGUNTA DO TECNICO": "LUBRIFICAÇÃO ROLA MQ TRAÇ",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "ROLAMENTO DA MAQ TRAÇÃO"
  },
  {
    "REFERENCIA": "3.1.6.2",
    "PERGUNTA DO TECNICO": "INTERGRIDADE ROLA MQ TRAÇ",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MAQUINA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": "ROLAMENTO DA MAQ TRAÇÃO"
  },
  {
    "REFERENCIA": "3.2.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE PROTETOR REG VEL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.2.2.1",
    "PERGUNTA DO TECNICO": "INTEGRID CANAL POLIA REG VEL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": "POLIA REGULADOR VELOCIDADE"
  },
  {
    "REFERENCIA": "3.2.3.1",
    "PERGUNTA DO TECNICO": "FUNCIONAM CONTAT ELET REG",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": "CONTATO ELETRICO REG VELOC"
  },
  {
    "REFERENCIA": "3.2.4",
    "PERGUNTA DO TECNICO": "INTEGRID LACRES SEG REG DE VEL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": "LACRES SEG DO REG VELOCIDAD"
  },
  {
    "REFERENCIA": "3.2.5.1",
    "PERGUNTA DO TECNICO": "INTEGRID CABO AÇO REG VEL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": "CABO DE AÇO REG VELOCIDAD"
  },
  {
    "REFERENCIA": "3.2.6.1",
    "PERGUNTA DO TECNICO": "FUNCIONAM ELET ANOT VALOR OGT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": "TESTE DO OGT"
  },
  {
    "REFERENCIA": "3.2.6.2",
    "PERGUNTA DO TECNICO": "FUNCIONAM MEC ANOT VALOR OGT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "REGULADOR DE VELOCIDADE",
    "CATEGORIA SUBMODULO": "TESTE DO OGT"
  },
  {
    "REFERENCIA": "3.3.1.1",
    "PERGUNTA DO TECNICO": "ISOLAMENTO TERMINAIS MOTOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MOTOR MR/MRL",
    "CATEGORIA SUBMODULO": "TERMINAIS  MOTOR MR/MRL"
  },
  {
    "REFERENCIA": "3.3.2.1",
    "PERGUNTA DO TECNICO": "FUNCIONAM ATERRAMENTO MOTOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "MOTOR MR/MRL",
    "CATEGORIA SUBMODULO": "ATERRAMENTO DO MOTOR"
  },
  {
    "REFERENCIA": "3.3.3.1",
    "PERGUNTA DO TECNICO": "INTEGRIDA BORRAC ACOP MOT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "BORRACHA ACOPLAMENTO MOT",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.3.4.1",
    "PERGUNTA DO TECNICO": "LUBRIFICAÇÃO ROLAMENT MOT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "ROLAMENTO MOTOR",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.3.4.2",
    "PERGUNTA DO TECNICO": "INTEGRIDADE ROLAMENT MOT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "ROLAMENTO MOTOR",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.3.5.1",
    "PERGUNTA DO TECNICO": "FUNCIONAMENT VENTILAÇ FORÇ MT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "VENTILAÇÃO FORÇADA MOTOR",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.3.6.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE COMUTAD ESCOV MOT",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "COMUTADOR E ESCOVA D MOTOR",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.3.7.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE EIXO DO MOTOR",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "EIXO DO MOTOR",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DAS LONAS",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.2",
    "PERGUNTA DO TECNICO": "INTEGRIDAD BRAÇOS DA SAPATA FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.3",
    "PERGUNTA DO TECNICO": "LUBRIFICAÇÃO ARTICULAÇÕES FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.4",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO DO BK FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.5",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO DO CPF DO FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.6",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DAS MOLAS DO FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.7",
    "PERGUNTA DO TECNICO": "MECANISMO ABERT HOMOLOG DO FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.8",
    "PERGUNTA DO TECNICO": "VALIDADE GRAXA DO FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.9",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DO EMBOLO DO FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.4.10",
    "PERGUNTA DO TECNICO": "DESLIZE DO FREIO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "FREIO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.5.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DOS CABO DE AÇO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CABOS DE AÇO MR MRL",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.5.2",
    "PERGUNTA DO TECNICO": "DIAMETRO DOS CABOS DE AÇO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CABOS DE AÇO MR MRL",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.6.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DA CINTA DE TRAÇÃO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CINTA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.6.2",
    "PERGUNTA DO TECNICO": "SENSORES DA CINTA DE TRAÇÃO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "CINTA DE TRAÇÃO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.7.1",
    "PERGUNTA DO TECNICO": "ACIONAMENTO MEC DO REG VEL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DO APARELHO SEGURANÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.7.2",
    "PERGUNTA DO TECNICO": "ACIONAMENTO ELETRIC DO AP SEG GRS",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DO APARELHO SEGURANÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.7.3",
    "PERGUNTA DO TECNICO": "ACIONAMENTO MEC DO AP SEG (CUNHA)",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DO APARELHO SEGURANÇA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.8.1.1",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO LIMITE PARADA SUB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE DE PARADA DE SUBIDA"
  },
  {
    "REFERENCIA": "3.8.1.2",
    "PERGUNTA DO TECNICO": "MEDIDA DE ATUAÇÃO LIMITE PARAD SUB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE DE PARADA DE SUBIDA"
  },
  {
    "REFERENCIA": "3.8.2.1",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO LIMITE FINAL SUB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE FINAL DE CURSO DE SUBIDA"
  },
  {
    "REFERENCIA": "3.8.2.2",
    "PERGUNTA DO TECNICO": "MEDIDA DE ATUAÇÃO LIMITE FINAL SUB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE FINAL DE CURSO DE SUBIDA"
  },
  {
    "REFERENCIA": "3.8.3.1",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO LIMITE PARADA DESC",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE DE PARADA DE DESCIDA"
  },
  {
    "REFERENCIA": "3.8.3.2",
    "PERGUNTA DO TECNICO": "MEDIDA DE ATUAÇÃO LIMITE PARAD DESC",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE DE PARADA DE DESCIDA"
  },
  {
    "REFERENCIA": "3.8.4.1",
    "PERGUNTA DO TECNICO": "FUNCIONAMENTO LIMITE FINAL DES",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE FINAL DE CURSO DE DESC"
  },
  {
    "REFERENCIA": "3.8.4.2",
    "PERGUNTA DO TECNICO": "MEDIDA DE ATUAÇÃO LIMITE FINAL DES",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "TESTE DOS LIMITES",
    "CATEGORIA SUBMODULO": "LIMITE FINAL DE CURSO DE DESC"
  },
  {
    "REFERENCIA": "3.9.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE PLACA SUSPENSÃO MRL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "PLACA DE SUSPENSÃO MRL",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "3.9.2",
    "PERGUNTA DO TECNICO": "FIXAÇÃO PLACA SUSPENSÃO MRL",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CASA DE MAQUINA MR/MRL",
    "SUB MODULO": "PLACA DE SUSPENSÃO MRL",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.1.1",
    "PERGUNTA DO TECNICO": "GRS FUJI",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "CONTATOS ELETRICOS SEGURANÇ",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.2.1",
    "PERGUNTA DO TECNICO": "EXISTENCIA CONF CTI 399",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "GUARD CORP BALUSTRAD TOP CAB",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.3.1",
    "PERGUNTA DO TECNICO": "FIXAÇÃO POLIA DEFLETORA CABINA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "POLIA DEFLETORA DA CABINA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.3.2",
    "PERGUNTA DO TECNICO": "LUBRIFICAÇÃO POLIA DEFLETORA CAB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "POLIA DEFLETORA DA CABINA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.3.3.1",
    "PERGUNTA DO TECNICO": "SISTEM ANTI-PULO PROT POL DEF CB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "PROTETOR  POLIA DEFLETORA CAB",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.4.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DA PLAC SUSP CAB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "PLACA DE SUSPENSÃO CABINA CIMA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.4.2",
    "PERGUNTA DO TECNICO": "FIXAÇÃO DA PLAC SUSP CAB",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "PLACA DE SUSPENSÃO CABINA CIMA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.5",
    "PERGUNTA DO TECNICO": "Operador de porta",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "PLACA DE SUSPENSÃO CABINA CIMA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.5.1",
    "PERGUNTA DO TECNICO": "Motor/freio do operador",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "PLACA DE SUSPENSÃO CABINA CIMA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "4.6",
    "PERGUNTA DO TECNICO": "Condições gerais em cima da cabina",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CABINA EM CIMA OPERADOR PORTA",
    "SUB MODULO": "PLACA DE SUSPENSÃO CABINA CIMA",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.1.1",
    "PERGUNTA DO TECNICO": "LUBRIFICAÇÃO GUIAS CAB E CP",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "GUIA( CAB E CONT PESO)",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.1.2",
    "PERGUNTA DO TECNICO": "LUBRIFICADOR DE GUIAS AUTOMÁTICO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "GUIA( CAB E CONT PESO)",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.2.1",
    "PERGUNTA DO TECNICO": "INTEGRIDA DOS CABOS DE AÇO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "CABO DE AÇO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.2.2",
    "PERGUNTA DO TECNICO": "TENSIONAMENT DO CAB DE AÇO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "CABO DE AÇO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.2.3",
    "PERGUNTA DO TECNICO": "POSICION DAS MARCAS DO CABO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "CABO DE AÇO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.2.4",
    "PERGUNTA DO TECNICO": "POSICION 25 MM DOS CLIPS",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "CABO DE AÇO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.3.1",
    "PERGUNTA DO TECNICO": "INTEGRIDADES DOS TIRANTES",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "TIRANTES",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.4.1.1",
    "PERGUNTA DO TECNICO": "INTEGRID PLACA SUSPENSÃO C PESO",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "CONJUNTO BAT DE CONT PESO",
    "CATEGORIA SUBMODULO": "PLACA SUSPENSÃO CONT PESO"
  },
  {
    "REFERENCIA": "5.4.2.1",
    "PERGUNTA DO TECNICO": "FIXAÇÃO DA POLIA DEFLETORA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.4.2.2",
    "PERGUNTA DO TECNICO": "LUBRIFICAÇÃO POLIA DEFLETORA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.4.2.4",
    "PERGUNTA DO TECNICO": "INTEGRIDADE DA POLIA DEFLETORA",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.4.2.5",
    "PERGUNTA DO TECNICO": "SISTEMA ANTIPULO  DA POLIA DEFLET",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.5.1",
    "PERGUNTA DO TECNICO": "Contrapeso",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.5.2",
    "PERGUNTA DO TECNICO": "Corrediça de contrapeso",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.5.3",
    "PERGUNTA DO TECNICO": "Aparelho de segurança do contrapeso",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.6",
    "PERGUNTA DO TECNICO": "Placa de sinalização",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.7",
    "PERGUNTA DO TECNICO": "Cabo de manobra ",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.8",
    "PERGUNTA DO TECNICO": "Pre-fiação / calha",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "5.9",
    "PERGUNTA DO TECNICO": "Iluminação caixa de corrida",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "CAIXA DE CORRIDA",
    "SUB MODULO": "POLIA DEFLETORA CONT PESO",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.1",
    "PERGUNTA DO TECNICO": "Botoeiras de pavimento",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.2",
    "PERGUNTA DO TECNICO": "Indicador de pavimento",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.3",
    "PERGUNTA DO TECNICO": "Portas de pavimento",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.4",
    "PERGUNTA DO TECNICO": "Soleira e corrediça",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.5",
    "PERGUNTA DO TECNICO": "Nivelamento",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.6",
    "PERGUNTA DO TECNICO": "Fecho eletromecânico",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.7",
    "PERGUNTA DO TECNICO": "Fecho hidráulico",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
  {
    "REFERENCIA": "6.2.8",
    "PERGUNTA DO TECNICO": "Disp arrast, cont elet, roletes, cabos transp, carrinho, mola.",
    "PERGUNTA DE EXIBIÇÃO": "",
    "MÓDULO": "PAVIMENTO",
    "SUB MODULO": "",
    "CATEGORIA SUBMODULO": ""
  },
]

export const modulos = {} as { [key: string]: string[] }
const modules = [...new Set(items.map(item => item.MÓDULO))]
modules.forEach(mod => modulos[mod] = [])
items.forEach(item => modulos[item.MÓDULO].push(item.REFERENCIA));


export const itemMap = {} as { [key: string]: ItemProps }

items.forEach(item =>  itemMap[item.REFERENCIA] = item)