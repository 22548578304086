import React, { ReactNode } from 'react';
import {
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useAuth } from '../../../context/AuthContext';
import { useNav } from '../../../context/NavigationContext';
import { FaWifi } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { config } from '../../../config/config';
export function MyAvatar() {
  const { signOut, user } = useAuth()
  const { offline, label } = useNav()


  return (
    <Flex alignItems={'center'} userSelect="none" >
      <Menu>
        <MenuButton
          as={Button}
          variant={'link'}
          cursor={'pointer'}
          minW={0}
        >

          <Avatar
            ml={3}
            w={"35px"}
            h={"35px"}
            name={user?.name}
            src={`${config.s3bucket}/user-${user?.id}`}
          />



        </MenuButton>
        <MenuList py={0}>
          <Link to={`/profile/${user?.id}`}>
            <MenuItem>Perfil</MenuItem>
          </Link>
          <MenuItem onClick={signOut}>Sair</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}