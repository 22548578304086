import { getRascunhos, getRelatoriosEmAberto, Relatorio } from "../services/relatorio";
import { useQuery } from "react-query"

export function useRascunhos({ page, pageSize }: { page: number, pageSize: number }) {
  let skip = 0;

  if (page > 1) {
    skip = (page - 1) * pageSize;
  }

  return useQuery(
    ["rascunhos-em-aberto", page],
    () => getRascunhos({ skip, take: pageSize }),
    {
      staleTime: 1000 * 60
    }
  )
}
