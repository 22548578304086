

import React from "react"
import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  useDisclosure,
  Spinner,

} from '@chakra-ui/react';
import {
  PlusSquareIcon
} from '@chakra-ui/icons';
import { FaCloudDownloadAlt, FaWifi, FaDatabase, FaUsers, FaFileAlt } from "react-icons/fa"
import { useLiveQuery } from 'dexie-react-hooks';
import { Link } from "react-router-dom";
import { Badge } from "../../PainelDeControle/components/Badge";
import { useAuth } from "../../../context/AuthContext";

export function AdminPainel() {
  const { user } = useAuth();

  if (user?.privilege! < 9) return <>Permissão insuficiente</>

  return (
    <Container maxW={'5xl'} py={8}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Badge label={"ONLINE"} />
          <Heading textAlign={"center"}>Painel Administrativo</Heading>
          <Text color={useColorModeValue('gray.500', 'gray.300')} fontSize={'lg'}>
            
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>

            <Link to={`/admin/painel/equipamentos`}>
              <Stack
                _hover={{
                  textDecoration: 'none',
                  bg: useColorModeValue("green.50", "green.900"),
                }}
                direction={'row'} align={'center'}
              >
                <Flex
                  w={8}
                  h={8}
                  align={'center'}
                  justify={'center'}
                  rounded={'full'}
                  bg={"green.100"}
                >
                  <Icon as={FaDatabase} color={'green.500'} w={5} h={5} />
                </Flex>
                <Flex justifyContent={"center"} alignItems="center">
                  <Text fontWeight={600}>Banco de dados (elevadores) </Text>
                  <Icon as={FaWifi} ml={1} color={"green.200"} />
                </Flex>
              </Stack>
            </Link>

            <Link to={`/admin/painel/colaboradores`}>
              <Stack
                _hover={{
                  textDecoration: 'none',
                  bg: useColorModeValue("green.50", "green.900"),
                }}
                direction={'row'} align={'center'}
              >
                <Flex
                  w={8}
                  h={8}
                  align={'center'}
                  justify={'center'}
                  rounded={'full'}
                  bg="blue.100"
                >
                  <Icon as={FaUsers} color={'blue.500'} w={5} h={5} />

                </Flex>
                <Flex justifyContent={"center"} alignItems="center">
                  <Text fontWeight={600}>Lista de Colaboradores</Text>
                  <Icon as={FaWifi} ml={1} color={"green.200"} />
                </Flex>
              </Stack>
            </Link>

            <Link to={`/admin/painel/relatorios`}>
              <Stack
                _hover={{
                  textDecoration: 'none',
                  bg: useColorModeValue("green.50", "green.900"),
                }}
                direction={'row'} align={'center'}
              >
                <Flex
                  w={8}
                  h={8}
                  align={'center'}
                  justify={'center'}
                  rounded={'full'}
                  bg="red.100"
                >
                  <Icon as={FaFileAlt} color={'red.500'} w={5} h={5} />

                </Flex>
                <Flex justifyContent={"center"} alignItems="center">
                  <Text fontWeight={600}>Cadastrar relatórios</Text>
                  <Icon as={FaWifi} ml={1} color={"green.200"} />
                </Flex>
              </Stack>
            </Link>

            <Link to={`/admin/painel/equipamentos/criar`}>
              <Stack
                _hover={{
                  textDecoration: 'none',
                  bg: useColorModeValue("green.50", "green.900"),
                }}
                direction={'row'} align={'center'}
              >
                <Flex
                  w={8}
                  h={8}
                  align={'center'}
                  justify={'center'}
                  rounded={'full'}
                  bg="red.100"
                >
                  <Icon as={FaFileAlt} color={'red.500'} w={5} h={5} />

                </Flex>
                <Flex justifyContent={"center"} alignItems="center">
                  <Text fontWeight={600}>Cadastrar novo equipamento</Text>
                  <Icon as={FaWifi} ml={1} color={"green.200"} />
                </Flex>
              </Stack>
            </Link>

          </Stack>
        </Stack>

      </SimpleGrid>
    </Container>
  );
}