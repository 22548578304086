import React from "react"
import {
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import { Relatorio } from '../../../services/relatorio';
import defaultImage from "../../../assets/rawpicture.png"

export function CardHero({ relatorio, handleChangeHeroImage }: { relatorio: Relatorio, handleChangeHeroImage: any }) {


  return (
    <>
      <label htmlFor='file'>
        <Box
          h={'210px'}
          bg={'gray.100'}
          mt={-6}
          mx={-6}
          mb={6}
          pos={'relative'}
        >
          <Image
            src={relatorio?.hero || defaultImage}
            w="100%"
            h="100%"
            objectFit={'fill'}
          />

          {!relatorio?.hero && (
            <Text
              position={"absolute"}
              bottom={"-4px"}
              fontWeight="bold"
              w="100%" display="flex"
              justifyContent="center"
              color="#fff"
            >
              Adicionar foto
            </Text>
          )}

        </Box>
      </label>
      <input
        type={"file"}
        name="file"
        id="file"
        style={{
          position: "absolute",
          top: "-1000px"
        }}
        onChange={handleChangeHeroImage} 
      />

    </>


  )
}