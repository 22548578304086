
import {
  Button,
  Text,
  Input,
  Container,
  Heading
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { db } from '../../../store'
import { useLiveQuery } from 'dexie-react-hooks'
import { useNavigate, useParams } from 'react-router-dom'

export function PageAdicionarDescricao() {

  const navigate = useNavigate()
  const { relatorio_id, inspecao_id, referencia, modulo_id } = useParams()

  const [descricao, setDescricao] = useState("")

  const relatorio = useLiveQuery(async () => {
    return await db.relatorio.get(relatorio_id!)
  })

  async function handleSetDescription() {
    const inspecao = relatorio?.inspecao.find(inspecao => inspecao.id === inspecao_id)
    const itemIndice = inspecao?.item?.findIndex(item => item.referencia === referencia)
    const inspecaoIndice = relatorio?.inspecao.findIndex(thisinspecao => thisinspecao.id === inspecao?.id);
    if (itemIndice === -1 || inspecaoIndice === -1) {
      return alert("Algo de errado aconteceu");
    }

    const toUpdate = {} as any;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.descricao`] = descricao;

    await db.relatorio.update(relatorio!.id, toUpdate)
    navigate(`/inspecao/${relatorio!.id}/${inspecao?.id}/${modulo_id}`)
  }

  return (
    <Container py={8}>
      <Heading mb="3"> Adicionar descrição</Heading>
      <Text>{referencia}</Text>
      <Input 
        mt="5"
        id="descricao-input" 
        type={"text"} 
        autoFocus 
        value={descricao} 
        onChange={(e) => setDescricao(e.target.value)}
      />
      <Button colorScheme='teal' w="full" mt="3" onClick={handleSetDescription} >
        Confirmar
      </Button>
    </Container>


  )

}