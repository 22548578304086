import { Box, Heading, Text , Button} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export function Success() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Parabéns!
      </Heading>
      <Text color={'gray.500'}>
        Relatório concluído com sucesso. Ele estará disponível para download em PDF no perfil do administrador.
      </Text>
      <Link to="/">

        <Button mt="3" colorScheme="green" variant="outline" w="full"> Voltar ao ínicio</Button>
      </Link>
    </Box>
  );
}