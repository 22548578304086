import React from "react"

import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { Field, FieldAttributes } from 'formik';

import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';


interface InputProps extends ChakraInputProps, FieldAttributes<any> {
  name: string;
  label?: string;
  isRequired?: boolean;
}

export function Input({ name, label, isRequired, ...props }: InputProps) {
  return (
    <FormControl>
      {label ? (
        <FormLabel htmlFor={name} color={useColorModeValue("gray.500", "gray.300")} >
          {label}
        </FormLabel>
      ) : (
        <></>
      )}

      <Field
        isRequired={isRequired}
        as={ChakraInput}
        name={name}
        id={name}
  
        _focus={{
          bg: useColorModeValue('gray.50', 'gray.900'),
        }}
        {...props}
      />
    </FormControl>
  );
}

interface PassInput extends ChakraInputProps, FieldAttributes<any> {
  label: string;
  name: string;
}

export function PassInput({ label, name, isRequired, ...props }: PassInput) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <Box w="100%">
      <FormControl >
        {label ? (
          <FormLabel htmlFor={name} color="gray.500">
            {label}
          </FormLabel>
        ) : (
          <></>
        )}

        <InputGroup>
          <Input isRequired={isRequired} name={name} type={show ? 'text' : 'password'} {...props} />
          <InputRightElement h="100%">
      
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Box>
  );
}