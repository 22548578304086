import React from "react"
//create a component that will be used to show a loading screen

import { Flex } from "@chakra-ui/react";
import { Spinner } from '@chakra-ui/react'

export function FullPageLoading() {
  return (
    <Flex w={"100vw"} h="80vh" justifyContent="center" alignItems={"center"}>
      <Spinner size='lg' />
    </Flex>
  )
}