import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export function AdminPageSuccess() {

  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Parabéns
      </Heading>
      <Text color={'gray.500'} mb={3}>
        Relatório cadastrado com sucesso! O que deseja fazer agora?
      </Text>

      <Link to="/admin/painel/relatorios">
        <Button w="full" colorScheme="green" mb={3} variant="outline">

          Novo relatório
        </Button>
      </Link>
      <Link to="/importar-relatorio">
        <Button w="full" colorScheme="purple" mb={3} variant="outline">

          Ver relatórios em aberto
        </Button>
      </Link>

    </Box>
  )
}
