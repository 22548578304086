import React, { useState } from "react"
import { Box, Checkbox, Container, Flex, Text , useColorModeValue} from "@chakra-ui/react";
import { Button } from "../../components/Button";
import { db } from "../../store";

export function Settings() {

  const [check, setCheck] = useState(false)

  async function handleOnClick() {

    let relatorio_id: string[] = []

    db.relatorio.each((relatorio) => {
      relatorio_id.push(relatorio.id)

    }).then(() => {

      db.relatorio.bulkDelete(relatorio_id)
        .then(() => alert("Deletado com sucesso"))
    })
  }
  return (
    <Container pt="10">
      <Text color={useColorModeValue("red.400","red.200")} mb="3">
        IMPORTANTE! Essa opção irá remover todos os dados offline. Não há como desfazer.
      </Text>

      <Flex mb="3">
        <Checkbox mr="2" onChange={(e) => { setCheck(e.target.checked) }} />
        <Text color={useColorModeValue("gray.600", "gray.300")}>Concordo com os termos acima</Text>

      </Flex>
      <Button w="full" disabled={!check} colorScheme={useColorModeValue("red", "orange")} label="DELETAR" onClick={handleOnClick}></Button>
    </Container>
  )
}