import { Container,Box, Flex,Text, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import React from "react";
import { FaLock, FaLockOpen } from "react-icons/fa";

export function InspecaoLoading({lock}: any) {
  return (
    <>
    <Container mt="5" >
      <Flex justify="space-between" >
        <Box w="25%">

          <Skeleton startColor="gray.300" endColor="gray.400" h="12px" w="75%" />
          <Skeleton startColor="gray.300" endColor="gray.400" h="16px" w="100%" mt="1" />
        </Box>
        <Box w="25%">

          <Skeleton startColor="gray.300" endColor="gray.400" h="12px" w="75%" />
          <Skeleton startColor="gray.300" endColor="gray.400" h="16px" w="75%" mt="1" />
        </Box>
        <Flex gap={3}>
          <SkeletonCircle startColor="green.100" endColor="green.200" h="40px" w="40px" />
          <SkeletonCircle startColor="blue.100" endColor="blue.200" h="40px" w="40px" />

        </Flex>
      </Flex>
      <Flex justify="flex-end" align="center" mt="4" mr="1">

        <Text color="#bbb" mr="2">Editar</Text>

        {lock ?
          <FaLock fontSize={20} style={{ color: "#bbb" }} />

          : <FaLockOpen fontSize={20} style={{ color: "#bbb" }} />}


      </Flex>
    </Container>
    <Container
      pt={4}
      pb={8}
      px={0}
      borderRadius={20}
    >
      <Skeleton
        borderRadius={10}
        startColor="gray.300"
        endColor="gray.400"
        height='60vh'
        mx={4}
      />
    </Container>
  </>

  )
}