import React from "react"
import { Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/LoginPage";
import { MainPage } from "../pages/Main";

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/login" element={<LoginPage />} />
      
      <Route path="/*" element={<MainPage />} />
    </Routes>
  );
}