import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import React from "react"
import { useAuth } from '../../../context/AuthContext';
import { useEquipamentos } from '../../../hooks/useEquipamentos';
import { Equipamento } from '../../../services/equipamentos';
import { FullPageLoading } from '../../../components/FullPageLoading';
import { PageConnectionProblem } from '../../../components/PageConnectionProblem';
import { EquipamentoCard } from '../components/Card';
import { LoadMore } from '../../../components/LoadMore';

export function AdminListaEquipamentos() {
  const {  user } = useAuth()

  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [visibleLoadMore, setVisibleLoadMore] = useState(true)
  const [equipamentos, setEquipamentos] = useState<Equipamento[]>([])
  const { data, isFetching, isError, isLoading } = useEquipamentos({ page, pageSize: 10 });
  const [loading, setLoading] = useState(false)

  useEffect(() => { 
    if (!data?.length) setVisibleLoadMore(false)
    if (data) setEquipamentos([...equipamentos, ...data])

  }, [data])


  if (user?.privilege! < 9) return <>Permissão insuficiente</>
  if (isLoading) return <FullPageLoading />
  if (isError) return <PageConnectionProblem />

  return (
    <Box p={4}   >
      {equipamentos?.map((equipamento, i) => {
         return  <EquipamentoCard
          key={`import-${i}`}
          equipamento={equipamento}        
        />

      })}
      <LoadMore onClick={() => setPage(page + 1)} state={isFetching} />
    </Box>
  )
}
