import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';


interface NavContextData {
  offline: boolean;
  label: any;
  setOffline:  React.Dispatch<React.SetStateAction<boolean>>
  setLabel: React.Dispatch<React.SetStateAction<any>>
}

const NavContext = createContext<NavContextData>({} as NavContextData);

export function useNav() {
  const context = useContext(NavContext);
  return context;
}

export const NavProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {

  const [offline, setOffline] = useState(false);
  const [label, setLabel] = useState<any>("")

  return (
    <NavContext.Provider
      value={{
        offline,
        setOffline,
        label, setLabel
      }}
    >
      {children}
    </NavContext.Provider>
  );
};