import { Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import React from "react"
import i404 from '../assets/404.svg';


export function PageConnectionProblem() {
  return <Flex w={"100%"} h="82vh" justifyContent="center" alignItems={"center"} direction="column">

      <Image src={i404} w={["90%", "400px"]} />
      <h1>
        <b>Sem conexão com a internet</b>
      </h1>
      <Link to="/">
        <Button label="Voltar" mt="2" />
      </Link>
    </Flex>
}