import { Inspecao } from "../../../services/relatorio";
import { Item, Relatorio } from './../../../services/relatorio/index';

export type Modulos = "POÇO" | "CABINA INTERNA" | "CASA DE MAQUINA MR/MRL"
  | "CABINA EM CIMA OPERADOR PORTA" | "CAIXA DE CORRIDA" | "PAVIMENTO";

export const ModuloMap = {
  "1": "POÇO",
  "2": "CABINA INTERNA",
  "3": "CASA DE MAQUINA MR/MRL",
  "4": "CABINA EM CIMA OPERADOR PORTA",
  "5": "CAIXA DE CORRIDA",
  "6": "PAVIMENTO"
} as { [key: string]: Modulos }

export const DecodeModulo = {
  "POÇO": "1",
  "CABINA INTERNA": "2",
  "CASA DE MAQUINA MR/MRL": "3",
  "CABINA EM CIMA OPERADOR PORTA": "4",
  "CAIXA DE CORRIDA": "5",
  "PAVIMENTO": "6"
} as { [key: string]: string }

export const ModuloList = Object.keys(ModuloMap).map(key => ModuloMap[key]);
export interface PerguntasProps { 
  referencias: string[] 
  modulo: string
  modulo_id: string
  query: RelatorioQuery
  lock: boolean

  reload: Function
  setLock: React.Dispatch<React.SetStateAction<boolean>>
}
export interface PickerEquipamento {
  query: RelatorioQuery
  modulo: string,
  reload: Function
}
export interface RelatorioQuery {
  relatorio: Relatorio,
  inspecao: Inspecao,
  perguntas: Item[]
  equipamentos: {
    equipamento_id: string,
    inspecao_id: string
  }[]
  totalItems: number
  totalFeitos: number
  porcentagem: number,
  porcentagemIndividual: { [key: string]: number},

}

