import { Container, Box, Text, Button, Divider, Flex, Avatar, Badge, Heading, Input, FormControl, FormLabel, Center } from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../services/api";
import { toBase64 } from "../../../utils";

import InputMask from "react-input-mask";

export function AdminListaColaboradoresCadastro() {

  const [name, setName] = React.useState("");
  const [telphone, setTelphone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [file, setFile] = React.useState<File>();
  const [filepreview, setFilePreview] = React.useState<string>(``);

  const navigate = useNavigate()


  function handleFilePick() {
    window.document.getElementById(`file-create-user`)?.click();
  }

  async function handleSubmit() {

    if (!name || !telphone || !email || !file) {
      alert(`Preencha todos os campos, incluindo a foto.`)
      return
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("telphone", telphone);
    formData.append("email", email);

    try {

      const response = await api.post(`/user`, formData)
      if (response.status < 300) {
        navigate(`/admin/painel/colaboradores`)
      }
    } catch (error) {
    }

  }

  return (
    <Container maxW={'5xl'} py={8}>


      <Center mb={5} flexDirection={`column`} cursor={`pointer`}>


        <Avatar
          w={145}
          h={145}
          name={name}
          onClick={handleFilePick}
          src={filepreview}
        />
        <Text color={`gray.400`} fontSize={10} mb={2}>
          Preencha a foto
        </Text>
      </Center>
      <Box>
        <FormControl mb={3}>
          <FormLabel>Nome</FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl mb={3}>
          <FormLabel>Email</FormLabel>
          <Input

            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl mb={6}>
          <FormLabel>Whatsapp</FormLabel>
          <Input
            as={InputMask}
            mask={`(99) 99999-9999`}
            value={telphone}
            onChange={(e) => setTelphone(e.target.value)}
          />
        </FormControl>
        <input
          type={`file`}
          id={`file-create-user`}
          style={{ display: `none` }}
          onChange={(e) => {
            const file = e.target.files?.[0]
            if (file) {
              setFile(file)
              toBase64(file)
                .then(data => setFilePreview(data as any))
            }
          }}
          accept={`image/*`}

        />
        <FormControl mb={3}>

          <Button
            w={`full`}
            colorScheme={`teal`}
            variant={`outline`}
            onClick={handleSubmit}

          >
            Cadastrar Usuário
          </Button>
        </FormControl>

      </Box>


    </Container>
  )
}

