import React from "react"
import { 
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Spinner
} from "@chakra-ui/react"

interface ButtonProps extends ChakraButtonProps {
  label?: any;
  state?: boolean
}

export function Button({ label, state, ...props }: ButtonProps) {
  return (
    <ChakraButton  
      {...props}
      disabled={props.disabled ? true : state ? true : false}
    >
      {state ? <Spinner /> : label}

    </ChakraButton>
  );
}