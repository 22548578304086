import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from './components/Notify';
import { AuthProvider } from './context/AuthContext';
import { MainRoutes } from './routes';
import { query } from './services/query-client';
import { theme } from './theme';
import ReloadPrompt from './ReloadPrompt';
import { AskForPermission } from './components/AskForPermission';
import { minutes, seconds } from './utils';
import { api } from './services/api';

function App() {

  return (
    <>
      <ReloadPrompt />
      <ToastContainer />
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={query}>
          <AuthProvider>
            <BrowserRouter>
              <MainRoutes />
            </BrowserRouter>
          </AuthProvider>
        </QueryClientProvider>

      </ChakraProvider>
    </>
  );
}

export default App;
