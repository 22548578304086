import { ReactNode } from 'react';
import React from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, SettingsIcon, MoonIcon, ViewOffIcon, CalendarIcon, LinkIcon } from '@chakra-ui/icons';
import { useAuth } from '../../context/AuthContext';
import { config } from '../../config/config';
import { NavLink } from './components/NavLink';
import { useNav } from '../../context/NavigationContext';
import { MyAvatar } from './components/MyAvatar';
import { FaAngleLeft, FaArrowLeft, FaHome, FaUserCog, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Items = [
  {
    href: "/",
    label: "Painel de Controle",
    icon: <FaHome />,
  },
  {
    href: "/admin",
    label: "Administração",
    icon: <FaUserCog />,
  },
  {
    href: "/imprimir",
    label: "Relatórios",
    icon: <CalendarIcon />,
  },
  {
    href: "/settings",
    label: "Configurações",
    icon: <SettingsIcon />,
  },
];



export function NavTopBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { offline, label } = useNav()
  let navigate = useNavigate();
  const { toggleColorMode } = useColorMode()

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Flex display={{ md: 'none' }} align="center" onClick={() => { navigate(-1) }}>
            <Flex  >
              <FaAngleLeft />
              <Text fontSize={10} mt={0.4} ml={1}>Voltar</Text>
            </Flex>
          </Flex>

          <Flex display={[`none`,`none`,`flex`]}>
            <HStack as={'nav'} spacing={4} onClick={onClose}>
              {Items.map((item, i) => (
                <NavLink key={`link-mb-${i}`} item={item} />
              ))}
            </HStack>
          </Flex>

          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />

          <Flex gap={1}>
            <IconButton
              onClick={toggleColorMode}
              colorScheme="gray"
              aria-label="Dark Mode"
            >
              <MoonIcon />
            </IconButton>
            <MyAvatar />
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4} onClick={onClose}>
              {Items.map((item, i) => (
                <NavLink key={`link-mb-${i}`} item={item} />
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>


    </>
  );
}