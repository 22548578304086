import {
  Container,
  Box,
  Stack,
  FormControl,
  Input,
  Button,
  useColorModeValue,
  Heading,
  Text,
  Flex,

} from "@chakra-ui/react";
import { useLiveQuery } from "dexie-react-hooks";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FullPageLoading } from "../../components/FullPageLoading";
import { PageNotFound } from "../../components/PageNotFound";
import { useNotify } from "../../hooks/useNotify";
import { db } from "../../store";

export function EditarOS() {
  const { relatorio_id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [input, setInput] = React.useState<{ [key: string]: string }>({})

  const relatorio = useLiveQuery(async () => {
    if (!relatorio_id) return null
    return await db.relatorio.get(relatorio_id)
  });

  useEffect(() => {
    if (!relatorio) return

    setInput(
      relatorio.inspecao.reduce((value, inspecao) => {
        value[inspecao.equipamento_id] = inspecao.OS || ""
        return value
      }, {} as { [key: string]: string })
    )

  }, [relatorio])

  async function handleSubmit() {

    if (!relatorio_id) return useNotify({ type: "info", message: { description: "Não foi possível salvar", title: "Falha no carregamento dos dados" } })
    if (!relatorio) return useNotify({ type: "info", message: { description: "Não foi possível salvar", title: "Falha no carregamento dos dados" } })

    setLoading(true)

    const toUpdate = {} as any;

    Object.keys(input).map(equipamento => {
      const indice = relatorio.inspecao.findIndex(inspecao => inspecao.equipamento_id === equipamento);
      if (indice !== -1)
        toUpdate[`inspecao.${indice}.OS`] = input[equipamento];
    })


    await db.relatorio.update(relatorio_id, toUpdate);

    setLoading(false)
    useNotify({ type: "success", message: { description: "Dados salvos com sucesso", title: "Dados salvos" } })
    navigate("/relatorio/" + relatorio_id + "/editar")
  }

  if (!relatorio_id) return <PageNotFound />
  if (!relatorio) return <FullPageLoading />
  return (
    <Container>
      <Box py={8} mb={1} minH="">
        <Heading mb="10" textAlign="center">Lista de Equipamentos</Heading>
        {relatorio?.inspecao?.map(inspecao => (
          <Stack
            direction={{ base: 'row', md: 'row' }}
            as={'form'}
            spacing={'12px'}
            mb="3"

          >
            <FormControl>
              <Input
                pattern="[0-9]*"
                type="tel"
                value={input[inspecao.equipamento_id]}
                onChange={(e) => {

                  setInput({ ...input, [inspecao.equipamento_id]: e.target.value })

                }}
                _placeholder={{
                  color: "gray.400"
                }}
                required
                placeholder={'Número da OS'}

              />
            </FormControl>
            <FormControl w={{ base: '30%', md: '40%' }}>
              <Button
                variant="outline"
                w="100%"
              >
                {inspecao?.equipamento_id}
              </Button>
            </FormControl>
          </Stack>
        ))}
      </Box>
      <Button isLoading={loading} onClick={handleSubmit} fontWeight={"bold"} colorScheme="linkedin" w="full">Confirmar</Button>
    </Container>
  )
}