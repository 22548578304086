import React from "react"
import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  useDisclosure,
  Spinner,

} from '@chakra-ui/react';
import {
  PlusSquareIcon
} from '@chakra-ui/icons';
import { FaWifi } from "react-icons/fa"
import { Badge } from './components/Badge';
import { AbrirModalImportarRelatorio } from './components/AbrirModalImportarRelatorio';
import { ListaOffline } from './components/ListaOffline';
import { AbrirModalRascunhos } from './components/AbrirModalRascunhos';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../store';
export function PainelDeControle() {

  const relatorios = useLiveQuery(async () => {
    return await db.relatorio.toArray()
  })

  return (
    <Container maxW={'5xl'} py={8}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Badge label={"OFFLINE"} />
          <Heading textAlign={"center"}>Meus relatórios</Heading>
          <Text color={useColorModeValue('gray.500', 'gray.300')} fontSize={'lg'}>
            É necessário importar um relatório para prosseguir.
            Após inspeção, o relatório deve ser sincronizado com
            a nuvem.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>

            <AbrirModalImportarRelatorio />
            <AbrirModalRascunhos />
            <ListaOffline relatorios={relatorios!} />

          </Stack>
        </Stack>

      </SimpleGrid>
    </Container>
  );
}