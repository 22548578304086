
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export  function AdminPaginaInicial() {
  return (
    <>
      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            PAINEL ADMINISTRATIVO <br />
            <Text as={'span'} color={'green.400'}>
              HPL ELEVADORES
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            Para prosseguir, é necessário que sua conta tenha verificação para acessar o painel.
          </Text>
          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            <Link to={`/admin/painel`}>
              <Button
                colorScheme={'green'}
                bg={'green.400'}
                rounded={'full'}
                px={6}
                _hover={{
                  bg: 'green.500',
                }}>
                Tenho verificação
              </Button>

            </Link>


          </Stack>
        </Stack>
      </Container>
    </>
  );
}