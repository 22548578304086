import { useEffect, useState } from "react";
import { Flex, Grid, GridItem, Image, Stack, StackDivider, useColorModeValue } from "@chakra-ui/react"
import { db } from "../../../store";
import { Relatorio } from "../../../services/relatorio";
import defaultImage from "../../../assets/rawpicture.png"
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import plugin from "dayjs/plugin/relativeTime";
import React from "react"
dayjs.extend(plugin)



export function Card({relatorio, ultimaAtualizacao}: {relatorio: Relatorio, ultimaAtualizacao: string},  ) {
  return (
    <Grid
      _hover={{
        bg: useColorModeValue("gray.50", "gray.900"),
        
      }}
      templateRows='repeat(3, 1fr)'
      templateColumns='repeat(9, 1fr)'
      gap={4}
      rowGap={1}
    >
      <GridItem colSpan={3} rowSpan={3}>
        <Image src={relatorio?.hero || defaultImage} h={"100%"} w="100%"  objectFit={"fill"} borderRadius={5} opacity={1} />
      </GridItem>
      <GridItem colSpan={6} rowSpan={1} textOverflow="ellipsis" overflow={"hidden"} whiteSpace="nowrap" fontWeight={"semibold"} >
        {relatorio?.name}
      </GridItem>
      <GridItem colSpan={6} rowSpan={1} color={useColorModeValue("gray.500", "gray.100") } fontWeight={"semibold"}>
        {relatorio.inspecao.length} Equipamento{relatorio.inspecao.length ? "s" : ""}
      </GridItem>
      <GridItem colSpan={6} rowSpan={1} color={useColorModeValue("gray.700", "gray.300")}>
        {dayjs(ultimaAtualizacao).locale("pt-br").fromNow()}
      </GridItem>
    </Grid>
  )
} 