import { Box, Text, Flex, Heading, useDisclosure, Image, useColorModeValue, Stack, StackDivider } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNotify } from '../../hooks/useNotify';
import { Rascunhos, Relatorio } from '../../services/relatorio';
import { db } from '../../store';
import { LoadMore } from './../../components/LoadMore';
import { useNavigate } from 'react-router-dom';
import { FullPageLoading } from '../../components/FullPageLoading';
import { PageConnectionProblem } from '../../components/PageConnectionProblem';
import { useRascunhos } from '../../hooks/useRascunhos';
import { Badge } from '../PainelDeControle/components/Badge';
import { Card } from './components/card';
import { Confirm } from './components/Confirm';
import React from "react"

export function ImportarBackup() {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [relatorio, setRelatorio] = useState<Relatorio>({} as Relatorio)
  const [rascunhos, setRascunhos] = useState<Rascunhos[]>([])
  const { data, isFetching, isError } = useRascunhos({ page, pageSize: 8 });
  const [loading, setLoading] = useState(false)


  async function Import(thisRelatorio?: Relatorio) {
    setLoading(true)
    try {
      if (!thisRelatorio) {
        await db.relatorio.update(relatorio.id, relatorio)
        navigate("/relatorio/" + relatorio.id)
      }
      else {
        await db.relatorio.add(thisRelatorio)
        navigate("/relatorio/" + thisRelatorio.id)
      }

    } catch (error: any) {
      useNotify({
        description: error?.message
      })
    }
    finally {
      setLoading(false)
    }

  }
  async function handleImport(relatorio: Relatorio) {

    const exist = await db.relatorio.where("id").equals(relatorio.id).toArray()

    if (exist.length) {
      setRelatorio(relatorio)
      onOpen()
    }
    else await Import(relatorio)
  }


  useEffect(() => {
    if (data) setRascunhos([...rascunhos, ...data])

  }, [data])

  if (loading) return <FullPageLoading />
  if (isError) return <PageConnectionProblem />

  return (
    <Box p={4} >

      <Flex direction={"column"}>
        <Badge label={"ONLINE"} />
        <Heading mt="3" textAlign={"center"} fontSize={'x-large'} color={useColorModeValue('red.400', 'red.200')}>
          Lista de Rascunhos
        </Heading>
        <Text color="gray.400" mt={2}>
          Ao utilizar o serviço de <b>backup</b> você compreende que todos os dados serão importados e não poderão ser recuperados os dados atuais.
        </Text>
      </Flex>

      <Stack
        spacing={4}
        py={10}
        divider={
          <StackDivider
            borderColor={useColorModeValue('gray.100', 'gray.700')}
          />
        }>
        {rascunhos?.map((rascunho, i) => {
          const relatorio = JSON.parse(rascunho?.texto);
          return <div 
            key={`rascunho-m-${i}`}
            onClick={() => handleImport(relatorio)}
          >
            <Card
              ultimaAtualizacao={rascunho.updatedAt}
              relatorio={relatorio}
            />
          </div>


        })}
      </Stack>
      <Confirm isOpen={isOpen} onOpen={onOpen} onClose={onClose} handleImport={() => Import()} />
      <LoadMore onClick={() => setPage(page + 1)} state={isFetching}  />
    </Box>
  )
}


