import axios from 'axios';
import { config } from '../config/config';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../hooks/useNotify';

export const api = axios.create({
  baseURL: config.apiBaseURL,
  timeout: config.apiTimeout,
});

api.interceptors.request.use(async (request) => {

  return request;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      useNotify({ error })
      // essa condição só faz sentido no reload da página.???
     // localStorage.removeItem("access_token")
     // localStorage.removeItem("refresh_token")
     // localStorage.removeItem("user_profile")
     // location.href = "/"
    }
    else {
      useNotify({ error })
      throw new Error(error);
    }
  }
);

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}