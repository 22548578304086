import {
  Text,
  Flex,
  Divider,
  Box,
  useColorModeValue
} from '@chakra-ui/react'
import { Relatorio } from '../../../services/relatorio'
import React from "react"

export function Card({ relatorio, onClick, bgColor }: {relatorio: Relatorio, onClick: any, bgColor?: string}) {
  return <Box
    onClick={() => onClick(relatorio)}
    py={2}
    px={3}
    mb={2}
    borderRadius={6}
    boxShadow={"sm"}
    bg={bgColor}
    _hover={{
      bg: "gray.100",
    }}
  >
    <Text color={useColorModeValue("gray.600", "gray.900") }  fontWeight={"semibold"} fontSize="sm">
      {relatorio.name}

    </Text>
    <Flex justify={""}>
      <Text color={useColorModeValue("gray.400", "gray.600")} fontWeight={"semibold"} fontSize={10}>
        {relatorio?.inspecao?.length} Equipamento{relatorio?.inspecao?.length > 1 && "s"}
      </Text>
      <Divider color={"black"} mx={1} orientation='vertical' />
      <Text color={useColorModeValue("gray.400", "gray.600") } fontWeight={"semibold"} fontSize={10}>
     
      </Text>
    </Flex>
  </Box>
}