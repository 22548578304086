import Dexie, { Table } from 'dexie';
import { Relatorio, Inspecao } from "../services/relatorio"
// Declare Database
//

interface Anexos {
  inspecao_id: string
  referencia: string 
  hero: string
}
class RelatorioDatabase extends Dexie {
  public relatorio!: Table<Relatorio, string>;
  public anexos!: Table<Anexos, string>;

  public constructor() {
    super("relatorio-database");
    this.version(1).stores({
      relatorio: "id,hero,user_id,name,status,createdBy,updatedBy,createdAt,updatedAt",
      anexos: "inspecao_id, referencia, hero"
    });
  }
}

export const db = new RelatorioDatabase();

