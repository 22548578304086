import React from "react"
import { Stack, StackDivider, useColorModeValue } from "@chakra-ui/react"
import { Relatorio } from "../../../services/relatorio";
import { CardListaOffline } from "./CardListaOffline";
import { Link } from "react-router-dom";




export function ListaOffline({ relatorios }: { relatorios: Relatorio[] }) {

  if (!relatorios?.length) {
    return <p>Nenhum relatório disponível</p>
  }

  return (
    <Stack
      spacing={4}
      divider={
        <StackDivider
          borderColor={useColorModeValue("gray.100", "gray.700")}
        />
      }>
      {relatorios?.map((relatorio, i) => (
        <Link key={`card-offline-mq44-${i}`} to={`/relatorio/${relatorio.id}`}>
          <CardListaOffline relatorio={relatorio} />
        </Link>
      ))}
    </Stack>
  )
}