import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { api } from '../services/api';
import { signIn as AuthSignIn, refreshToken, getMyProfile } from '../services/auth/';
export interface User {
  id: string;
  name: string;
  email: string;
  telphone: string;
  privilege: number;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}


interface AuthContextData {
  signed: boolean;
  user: User | null;
  loading: boolean;
  signIn(email: string, password: string): Promise<boolean | { error: any }>;
  signOut(): void;
  loadMe(): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<null | User>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function persistAuthenticate() {
      const access_token = localStorage.getItem('access_token');
      const refresh_token = localStorage.getItem('refresh_token');

      if (access_token && refresh_token) {
        try {
          api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

          const user = await refreshToken(refresh_token);

          if (!user) throw new Error("User failed to login")

          localStorage.setItem('access_token', user.access_token);
          localStorage.setItem('refresh_token', user.refresh_token);
          api.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${user.access_token}`;

          setUser(user.payload as User);

          try { await loadMe(); } 
          catch (error) { }
          
        }
        catch (error) {
          const user_profile = localStorage.getItem('user_profile')
          if (user_profile)
            setUser(JSON.parse(user_profile));

        }

      }

      setLoading(false);
    }
    persistAuthenticate();
  }, []);

  async function loadMe() {
    const { data } = await getMyProfile();
    setUser(data);

    localStorage.setItem('user_profile', JSON.stringify(data));
  }

  async function signIn(email: string, password: string) {
    const { data: user } = await AuthSignIn({ email, password });

    if (user) {
      setUser(user.payload as User);
      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('refresh_token', user.refresh_token);

      api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${user.access_token}`;

      loadMe();

    }
    return Boolean(user);
  }


  function signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    api.defaults.headers.common['Authorization'] = '';
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        signIn,
        signOut,
        loading,
        loadMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};