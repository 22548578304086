import { Container, Box, Text, Button, Divider, Flex, Avatar, Badge, Heading, Input, FormControl, FormLabel, Center, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../services/api";
import { toBase64 } from "../../../utils";


import InputMask from "react-input-mask";


interface CreateEquipamentoDTO {
  id: string;
  name: string;


  endereco?: string;
  bairro?: string;
  modelo?: string;
  abertura?: string;
  maquina?: string;
  marca?: string;
  linha?: string;
  tipo?: string;
  setor?: string;
  zona?: string;


  cep?: string;
  localizacao?: string | null;
  apelido?: string | null;
  qtdParadas?: number | null;
  cepServico?: string | null;
  enderecoServico?: string | null;
}

export function AdminCadastrarEquipamento() {

  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");
  const [allFields, setAllFields] = useState(false)
  const [append, setAppend] = React.useState({

    endereco: "",
    bairro: "",
    modelo: "",
    abertura: "",
    maquina: "",
    marca: "",
    linha: "",
    tipo: "",
    setor: "",
    zona: "",


    cep: "",
    localizacao: "",
    apelido: "",
    qtdParadas: "",
    cepServico: "",
    enderecoServico: "",
  })
  const navigate = useNavigate()


  function handleFilePick() {
    window.document.getElementById(`file-create-user`)?.click();
  }

  async function handleSubmit() {

    if (!name) {
      alert(`Campos obrigatórios: Número do equipamento, Nome do equipamento`)
      return
    }
    try {
      const safeCopy = JSON.parse(JSON.stringify(append))
      
      Object.keys(safeCopy).forEach(key => {
        if (!safeCopy?.[key]) {
          delete safeCopy[key]
        }
      })
      const payload: CreateEquipamentoDTO = {
        ...safeCopy,
        id,
        name
      }
      const response = await api.post(`/equipamento`, payload)

      if (response.status < 300) {
        navigate(`/admin/painel/equipamentos/criar/ok`)
      }
    } catch (error) {
    }

  }

  return (
    <Container maxW={'5xl'} py={8}>
      <Flex align={"center"} justify="end" mb={5} color="green.400">

        <FormLabel>Exibir todos os campos</FormLabel>
        <Switch  checked={allFields} onChange={(e) => setAllFields(e.target.checked)} />
      </Flex>

      <Box>
        <FormControl mb={3}>
          <FormLabel>Número do equipamento {" "}
            <Text as="span" color="red.400" fontSize={10}>(Obrigatório)</Text>

          </FormLabel>
          <Input
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </FormControl>

        <FormControl mb={3}>
          <FormLabel>Nome do edifício
            {" "}
            <Text as="span" color="red.400" fontSize={10}>(Obrigatório)</Text>

          </FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>

        {
          allFields ? (
            <>
              <FormControl mb={3}>
                <FormLabel>Endereço

                </FormLabel>
                <Input
                  value={append.endereco}
                  onChange={(e) => setAppend({ ...append, endereco: e.target.value, enderecoServico: e.target.value })}
                />
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>CEP
                </FormLabel>
                <Input
                  value={append.cep}
                  onChange={(e) => setAppend({ ...append, cep: e.target.value, cepServico: e.target.value })}
                />
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>Marca
                </FormLabel>
                <Input
                  value={append.marca}
                  onChange={(e) => setAppend({ ...append, marca: e.target.value })}
                />
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>Modelo
                </FormLabel>
                <Input
                  value={append.modelo}
                  onChange={(e) => setAppend({ ...append, modelo: e.target.value })}
                />
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>Máquina
                </FormLabel>
                <Input
                  value={append.maquina}
                  onChange={(e) => setAppend({ ...append, maquina: e.target.value })}
                />
              </FormControl>

              <FormControl mb={3}>
                <FormLabel>Qtd de paradas
                </FormLabel>
                <Input
                  value={append.qtdParadas}
                  onChange={(e) => setAppend({ ...append, qtdParadas: e.target.value })}
                />
              </FormControl>


              <FormControl mb={3}>
                <FormLabel>Setor
                </FormLabel>
                <Input
                  value={append.setor}
                  onChange={(e) => setAppend({ ...append, setor: e.target.value })}
                />
              </FormControl>



              <FormControl mb={3}>
                <FormLabel>Zona
                </FormLabel>
                <Input
                  value={append.zona}
                  onChange={(e) => setAppend({ ...append, zona: e.target.value })}
                />
              </FormControl>
            </>
          ) : <></>
        }
        <FormControl mb={3}>

          <Button
            w={`full`}
            colorScheme={`teal`}
            variant={`outline`}
            onClick={handleSubmit}

          >
            Cadastrar Usuário
          </Button>
        </FormControl>

      </Box>


    </Container>
  )
}

