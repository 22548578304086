import React from "react"
import { Flex, Grid, GridItem, Image, useColorModeValue, } from "@chakra-ui/react"
import { Relatorio } from "../../../services/relatorio";
import defaultImage from "../../../assets/rawpicture.png"




export function CardListaOffline({relatorio}: {relatorio: Relatorio} ) {
  return (
    <Grid
      _hover={{
        bg: useColorModeValue("gray.50", "gray.900"),
        
      }}
      h='60px'
      templateRows='repeat(2, 1fr)'
      templateColumns='repeat(8, 1fr)'
      gap={4}
    >
      <GridItem colSpan={2} rowSpan={2}>
        <Image src={relatorio?.hero || defaultImage} h={"100%"} w="100%"  objectFit={"fill"} borderRadius={5} opacity={1} />
      </GridItem>
      <GridItem colSpan={6} textOverflow="ellipsis" overflow={"hidden"} whiteSpace="nowrap" fontWeight={"semibold"} >
        {relatorio.name}
      </GridItem>
      <GridItem colSpan={6} color={"gray.500"}>
        {relatorio.inspecao.length} Equipamento{relatorio.inspecao.length ? "s" : ""}
      </GridItem>
    </Grid>
  )
} 