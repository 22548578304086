import { extendTheme } from '@chakra-ui/react';

const colors = {
  new: {
    cyan: '#003E53',
    royal: '#012B97',
    marine: '#043553',
  },
};

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
};

export const theme = extendTheme({ colors, breakpoints });