import {
  Container,
  Heading,
  Button,
  VStack,
  Text,
  Flex,
  Spinner,
  Box,
  Image
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react"
import { Carousel } from "../../../components/Carousel";
import { db } from "../../../store";
import { PerguntasProps } from "../types";
import { itemMap } from "../../../store/items";
import { ModuloCompleto } from "./ModuloCompleto";
import { FaCircle, FaEdit, FaLock, FaLockOpen } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useNotify } from "../../../hooks/useNotify";
import { Compress } from "../../../utils";


export function PerguntasSlider(props: PerguntasProps) {
  const { referencias, modulo, query, modulo_id, lock, setLock, reload } = props;
  const { inspecao, relatorio, perguntas } = query

  const navigate = useNavigate()

  async function handleResposta(resposta: number, referencia: string) {
    const itemIndice = inspecao.item?.findIndex(item => item.referencia === referencia)
    const inspecaoIndice = relatorio?.inspecao.findIndex(thisinspecao => thisinspecao.id === inspecao.id);
    if (itemIndice === -1 || inspecaoIndice === -1) {
      return alert("Algo de errado aconteceu");
    }
    const toUpdate = {} as any;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.resposta`] = resposta;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.responsabilidade`] = null;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.observacao`] = null;
    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.acao`] = null;

    await db.relatorio.update(relatorio.id, toUpdate)
  }

  async function handleChangeHeroImage(e: React.ChangeEvent<HTMLInputElement>, referencia: string) {
    const itemIndice = inspecao.item?.findIndex(item => item.referencia === referencia)
    const inspecaoIndice = relatorio?.inspecao.findIndex(thisinspecao => thisinspecao.id === inspecao.id);
    if (itemIndice === -1 || inspecaoIndice === -1) {
      return alert("Algo de errado aconteceu");
    }

    const file = e.target.files?.[0];

    if (!file) {
      return useNotify({ icon: "error", description: "Falha ao trocar imagem." });
    }
    const hero = await Compress(file)
    const toUpdate = {} as any;

    toUpdate[`inspecao.${inspecaoIndice}.item.${itemIndice}.anexo`] = hero || null;
    await db.relatorio.update(relatorio!.id, toUpdate)
  }

  return (
    <Container
      pt={4}
      pb={8}
      px={0}
    >

      <Flex justify="flex-end" align="center" px={5} mb="2">

        <Text color="#bbb" mr="2">Editar</Text>
        {lock
          ? <FaLock fontSize={20} style={{ color: "#bbb" }} onClick={() => setLock(false)} />
          : <FaLockOpen fontSize={20} style={{ color: "#bbb" }} onClick={() => setLock(true)} />
        }
      </Flex>
      {(!perguntas?.length && inspecao?.item?.length) ? (
        <ModuloCompleto
          relatorio_id={relatorio.id}
          relatorio={relatorio}
          inspecao_id={inspecao.id}
          modulo={modulo}
          referencias={referencias}
          inspecao={inspecao}
          reload={reload}
        />
      ) : (

        <Carousel gap={32}>
          {perguntas?.map((pergunta, i) => {
            return (
              <Flex
                key={`card-pergunta-${i}`}
                boxShadow="md"
                justifyContent="space-between"
                flexDirection="column"
                overflow="hidden"
                color="gray.300"
                bg="blackAlpha.800"
                rounded={5}
                flex={1}
                minH={`60vh`}
                p={5}
              >
                <Box>
                  <VStack mb={6} justify="center">
                    <Text color={"gray.400"} align={"center"} fontSize={"small"}>{itemMap?.[pergunta?.referencia]?.["MÓDULO"]} {pergunta?.referencia}</Text>
                    <Text color={"gray.400"} fontSize={"small"}>
                      {itemMap?.[pergunta?.referencia]?.["SUB MODULO"]}
                    </Text>

                  </VStack>
                  <VStack mb={6} justify="center">
                    <Heading textAlign={"center"} fontSize={{ base: "xl", md: "2xl" }} >
                      {pergunta?.pergunta}
                    </Heading>
                  </VStack>

                  {(!lock && pergunta?.resposta) && (
                    <Flex p={3} w="full" justify="center" align="center" direction={"column"}>
                      <Text color={
                        pergunta?.resposta === 1 ? "green.400" :
                          pergunta?.resposta === 2 ? "red.400" : "yellow.400"
                      }>
                        {pergunta?.resposta === 1 ? "CONFORME" :
                          pergunta?.resposta === 2 ? "NÃO CONFORME" : "NÃO APLICA"}
                      </Text>

                      {pergunta?.responsabilidade && <Text fontSize={10} color="gray.200">
                        Responsável: {pergunta?.responsabilidade}
                      </Text>}
                      {pergunta?.acao && <Text fontSize={10} color="gray.200">
                        Ação: {pergunta?.acao}
                      </Text>
                      }
                      {pergunta?.observacao && <Text fontSize={10} color="gray.200">
                        Observação: {pergunta?.observacao}
                      </Text>

                      }
                      {pergunta?.anexo && (
                        <Image src={pergunta?.anexo} w="50px" h="50px" />
                      )}

                    </Flex>
                  )}


                  {pergunta?.descricao ? (
                    <Link to={`/inspecao/${relatorio.id}/${inspecao.id}/${modulo_id}/add-description/${pergunta.referencia}`}>
                      <Text
                        w="full"
                        justifyContent={"center"}
                        alignItems="center"
                        display={"flex"}
                        flexDir="row"
                      >
                        {pergunta?.descricao}
                        <FaEdit style={{ marginLeft: 10 }} />
                      </Text>
                    </Link>
                  ) : (
                    <Link to={`/inspecao/${relatorio.id}/${inspecao.id}/${modulo_id}/add-description/${pergunta.referencia}`}>
                      <Button
                        variant={"outline"}
                        w="full"
                        _hover={{ opacity: 0.4 }}
                      >
                        Adicionar descrição
                      </Button>
                    </Link>
                  )}


                </Box>

                <Flex justifyContent="space-between" gap={2}>

                  <Button
                    id={`confirmar-${pergunta.referencia}`}
                    onClick={async () => {

                      handleResposta(1, pergunta.referencia);

                    }}
                    py={8}
                    colorScheme="green"
                    fontWeight="bold"
                    size="md"
                    w="full"
                    flexDir={"column"}
                  >
                      <Text>OK</Text>
                    <Text fontSize={9}>Conforme</Text>

                  </Button>
                  <Button
                    py={8}
                    colorScheme="red"
                    fontWeight="bold"
                    size="md"
                    w="full"
                    flexDir={"column"}
                    onClick={()=> {
                      navigate(`/inspecao/${relatorio.id}/${inspecao.id}/${modulo_id}/nf/${pergunta.referencia}`)
                    }}
                  >
                    <Text>NC</Text>
                    <Text fontSize={9}>Não consta</Text>
                  </Button>

                  <Button
                    id={`naoaplica-${pergunta.referencia}`}
                    onClick={async () => {

                      handleResposta(3, pergunta.referencia)

                    }}
                    py={8}
                    colorScheme="yellow"
                    fontWeight="bold"
                    size="md"
                    w="full"
                    flexDir={"column"}
                  >
                    <Text>NA</Text>
                    <Text fontSize={9}>Não aplica</Text>
                  </Button>

                  <Button
                    id={`okcomfoto-${pergunta.referencia}`}
                    onClick={async () => {

                      navigate(`/inspecao/${relatorio.id}/${inspecao.id}/${modulo_id}/conforme-comfoto/${pergunta.referencia}`)

                    }}
                    py={8}
                    colorScheme="teal"
                    fontWeight="bold"
                    size="md"
                    w="full"
                    flexDir={"column"}
                  >
                    <Text>OK</Text>
                    <Text fontSize={9}>Com foto</Text>
                  </Button>
                </Flex>
              </Flex>
            )
          })}
        </Carousel>
      )}
    </Container>
  )

}