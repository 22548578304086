import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'

export function Confirm ({ isOpen, onOpen, onClose, handleDelete }: any) {


  const cancelRef = React.useRef<any>()

  return (
    <AlertDialog
    motionPreset='slideInBottom'
    leastDestructiveRef={cancelRef}
    onClose={onClose}
    isOpen={isOpen}
    isCentered
  >
    <AlertDialogOverlay />
  
    <AlertDialogContent>
      <AlertDialogHeader>Tem certeza disso?</AlertDialogHeader>
      <AlertDialogCloseButton />
      <AlertDialogBody>
       Essa operação não poderá ser desfeita! Deseja continuar?
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button ref={cancelRef} onClick={onClose}>
          Cancelar
        </Button>
        <Button colorScheme='red' ml={3} onClick={handleDelete}>
          Sim, Deletar
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
  )

}