import React from 'react'

import { useRegisterSW } from 'virtual:pwa-register/react'

const style = `
.ReloadPrompt-container {
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
}
.ReloadPrompt-date {
  visibility: hidden;
}
.ReloadPrompt-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0 #8885;
  background-color: blue;
  color: white !important;
}
.ReloadPrompt-toast-message {
  margin-bottom: 8px;
}
.ReloadPrompt-toast-button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 3px 10px;
  width: 100%;
}

`

function ReloadPrompt() {
  // replaced dynamically
  const buildDate = '__DATE__'
  // replaced dyanmicaly
  const reloadSW = '__RELOAD_SW__'

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        r && setInterval(() => {
          // eslint-disable-next-line no-console
          console.log('Checking for sw update')
          r.update()
        }, 20000 /* 20s for testing purposes */)
      }
      else {
        // eslint-disable-next-line prefer-template,no-console
        console.log('SW Registered: ' + r)
      }
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error)
    },
  })

  const close = () => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }

  return (
    <div className="ReloadPrompt-container">
      <style>
        {style}
      </style>
      { (offlineReady || needRefresh)
          && <div className="ReloadPrompt-toast">
            {needRefresh && (
             <>
              <h3>
                Nova atualização detectada!
              </h3>
              <p>
                É recomendável o uso do Wifi para download.
              </p>
             </>
            )}
              
            { needRefresh && <button className="ReloadPrompt-toast-button" onClick={() => updateServiceWorker(true)}>Atualizar</button> }

          </div>
      }
      <div className="ReloadPrompt-date">{buildDate}</div>
    </div>
  )
}

export default ReloadPrompt
