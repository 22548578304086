import React from "react"
import {
  Flex,
  Box,
  Stack,
  Link as LinkChakra,
  Heading,
  Text,
  useColorModeValue,
  VStack,
  Spinner,
} from '@chakra-ui/react';

import { Formik, Form } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PassInput, Input } from '../../components/Input';
import { useAuth } from '../../context/AuthContext';

import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { useNotify } from '../../hooks/useNotify';

export function LoginPage() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const [loading, setLoading] = useState(false);


  async function LoginFunction({ email, password }: { email: string, password: string }) {

    setLoading(true);
    try {
      await signIn(email, password);
      navigate("/")
    } catch (error) {
    }
    setLoading(false);
  }

  return (
    <Flex
      minH={'100vh'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} minW={["100%", "500px"]}  py={12} px={3}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Bem vindo!</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            Portal unificado  <LinkChakra color={'blue.400'}>HPL ELEVADORES</LinkChakra>
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={4}>
          <Stack spacing={5}>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={LoginFunction}
              render={() => {
                return (
                  <Form>
                    <VStack spacing="8" align="flex-start">
                      <VStack spacing="4" align="flex-start" w="100%">
                        <Input
                          name="email"
                          variant="filled"
                          size="lg"
                          label="Email"
                          isRequired
                        />
                        <PassInput
                          name="password"
                          variant="filled"
                          size="lg"
                          label="Senha"
                          isRequired
                        />
                      </VStack>
                      <Button
                        state={loading}
                        label={"Entrar"}
                        type="submit"
                        w="100%"
                        bg={'blue.400'}
                        color={'white'}
                        _hover={{
                          bg: 'blue.500',
                        }}
                      />
                    </VStack>
                  </Form>
                );
              }}
            />

          </Stack>

        </Box>
      </Stack>
    </Flex>
  )
}