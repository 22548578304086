import React from "react"
import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Image,
  Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Illustration from "../../assets/elevator-bg.png"

export function MainPage() {
  return (
    <Container maxW={'5xl'} >
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}>
        <Heading
          fontWeight={700}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}
          p={0}
          m={0}
        >
          HPL ELEVADORES{' '}

        </Heading>
        <Heading
          fontWeight={700}

          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          color={'blue.400'}
          lineHeight={'110%'}
          style={{ margin: 5 }}
        >
          Portal Unificado


        </Heading>
        <Text color={'gray.500'} maxW={'3xl'}>
          Disponível em todas as plataformas, relatório de inspeção anual, RVT, métricas de relatórios, e muito mais.
        </Text>
        <Stack spacing={2} direction={'row'}>
          <Link to="/login">
            <Button
              rounded={'full'}
              px={6}
              colorScheme={'blue'}
              bg={'blue.400'}
              _hover={{ bg: 'blue.500' }}>
              Inspecionar
            </Button>
          </Link>

          <Button
            rounded={'full'}
            px={6}
            colorScheme={'green'}
            bg={'green.400'}
            _hover={{ bg: 'green.500' }}>
            Administração
          </Button>

        </Stack>
        <Flex w={'full'} justifyContent="center" >
          <Image src={Illustration} width={["80%", "40%"]} />
        </Flex>
      </Stack>

    </Container>
  );
}