import React from "react"
import { Text, useColorModeValue } from "@chakra-ui/react";

export function Badge({ label }: { label: string }) {
  return <Text
    textAlign={"center"}
    textTransform={'uppercase'}
    color={useColorModeValue('green.400', 'green.400')}
    fontWeight={600}
    fontSize={'sm'}
    bg={useColorModeValue("green.50", "transparent")}
    border={useColorModeValue("", "1px solid green")}
    p={2}
    alignSelf={'center'}
    rounded={'md'}>
    {label}
  </Text>
}