import {
  Box,
  Text,
  Stack,
  Avatar,
  Flex,
  Container,
  Grid,
  useDisclosure,
  Button,
  GridItem,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageNotFound } from '../../components/PageNotFound';
import { db } from '../../store';
import dayjs from 'dayjs';
import { FullPageLoading } from '../../components/FullPageLoading';
import { useLiveQuery } from 'dexie-react-hooks';
import { FaCogs, FaEdit } from 'react-icons/fa';
import { ProgressBar } from '../Relatorio/components/ProgressBar';
import { CardHero } from '../Relatorio/components/CardHero';
import { useNotify } from '../../hooks/useNotify';
import { Confirm } from './components/Confirm';
import { api } from '../../services/api';
import React from "react"
import { config } from '../../config/config';
import { Compress } from '../../utils';

export function EditarRelatorio() {
  const { relatorio_id } = useParams()
  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)


  const relatorio = useLiveQuery(async () => {
    if (!relatorio_id) return null
    return await db.relatorio.get(relatorio_id)
  });


  const progress = useMemo(() => {

    if (!relatorio) return 0;

    const totalItems = relatorio?.inspecao?.length * (relatorio?.inspecao?.[0]?.item?.length || 0)
    const totalFeitos = relatorio.inspecao.reduce((total, inspecao) => {

      total += inspecao.item?.reduce((accumulator, currently) => {
        accumulator += currently?.resposta ? 1 : 0
        return accumulator
      }, 0)!

      return total
    }, 0)

    return Math.floor(totalFeitos / totalItems * 100)
  }, [relatorio])



  if (!relatorio_id) return <PageNotFound />
  if (!relatorio) return <FullPageLoading />

  async function handleDelete() {
    await db.relatorio.delete(relatorio_id!);
    useNotify({
      type: "success",
      message: {
        title: "Operação concluída.",
        description: "O relatório foi deletado com sucesso"
      }
    })
    navigate("/")
  }

  async function handleChangeHeroImage(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return useNotify({ icon: "error", description: "Falha ao trocar imagem." });
    }
    const hero = await Compress(file)
    await db.relatorio.update(relatorio!.id, { hero })
  }

  async function handleSincronizarRascunho() {
    if (!relatorio)
      return useNotify({ message: { title: "Falha na operação", description: "Não consegui resgatar as informações necessárias." } })

    try {
      setLoading(true)

      const response = await api.post("/relatorio/rascunho/upload", {
        relatorio_id: relatorio.id,
        texto: JSON.stringify(relatorio)
      })

      if (response.status !== 200) {
        throw new Error("Não foi possível sincronizar o rascunho")
      }
      useNotify({
        type: "success",
        message: {
          title: "Operação concluída.",
          description: "Relatório sincronizado com sucesso",
        }
      })
    } catch (error: any) {
      useNotify({
        type: "error",
        message: {
          title: "Falha na operação",
          description: error?.message || "Não foi possível sincronizar o rascunho"
        }
      })
    }
    finally {
      setLoading(false)
    }

  }
  function handleTrocarFoto() {
    window?.document?.getElementById("file")?.click()
  }

  return (
    <Container>
      <Box py={4} mb={10}>


        <CardHero relatorio={relatorio!} handleChangeHeroImage={handleChangeHeroImage} />
        <Flex mt="3" w="100%" align="center" justify="space-between">
          <Box>
            <Text fontWeight={"bold"}>{relatorio?.name}</Text>
            <Text fontWeight={"bold"} color="gray.400" fontSize={"10px"}>{relatorio?.id} </Text>
          </Box>
          <ProgressBar progress={progress} />
        </Flex>

        <Grid templateColumns={"repeat(3, 1fr)"} gap={2} mt="3">
          {relatorio?.inspecao?.map((inspecao, o) => {
            const done = typeof inspecao.OS === "string" && inspecao.OS.length > 0
            return (
              <Link key={`inspecao-equipamentos-x-${o}`} to={`/relatorio/${relatorio?.id}/editar/os`}>
                <GridItem color="#fff">
                  <Button
                    w="full"
                    variant="outline"
                    colorScheme={done ? "green" : "red"}

                  >
                    {inspecao.equipamento_id}

                  </Button>
                </GridItem>
              </Link>
            )
          })}
        </Grid>

        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Link to={`/profile/${relatorio.user_id}`}>
            <Avatar src={`${config.s3bucket}/user-${relatorio?.user_id}`} />
          </Link>

          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={600}>Técnico Responsável</Text>
            <Text color={'gray.500'}>
              {dayjs(relatorio?.createdAt).format('DD/MM/YYYY [as] HH:mm:ss')}
            </Text>
          </Stack>
        </Stack>



      </Box>

      <Link to={`/inspecao/${relatorio.id}/${relatorio.inspecao[0].id}/1`}>
        <Button colorScheme={"green"} variant="outline" mt={5} w={"100%"} disabled={loading}>
          INSPECIONAR
        </Button>
      </Link>

      <Button
        onClick={handleTrocarFoto}
        w="100%"
        colorScheme={"linkedin"}
        mt="3"
        variant="outline"
        disabled={loading}
      >
        TROCAR FOTO
      </Button>

      <Button
        onClick={handleSincronizarRascunho}
        w="100%"
        colorScheme={"yellow"}
        mt="3"
        variant="outline"
        isLoading={loading}
      >
        SALVAR RASCUNHO
      </Button>

      <Button
        onClick={onOpen}
        disabled={loading}
        variant="outline"
        w="100%"
        colorScheme="red"
        mt="3"
      >
        DELETAR
      </Button>
      <Confirm isOpen={isOpen} onOpen={onOpen} onClose={onClose} handleDelete={handleDelete} />

    </Container>

  )
}