
import React from "react"

import {
  Text,
  Flex,
  Divider,
  Box,
  useColorModeValue
} from '@chakra-ui/react'
import { Equipamento } from "../../../services/equipamentos"


export interface EquipamentoCardProps {
 equipamento: Equipamento
}
export function EquipamentoCard({equipamento}: EquipamentoCardProps) {
  return (
    <Box
      onClick={() => {}}
      py={2}
      px={3}
      mb={2}
      borderRadius={6}
      boxShadow={"sm"}
  
    >
      <Text  fontWeight={"semibold"} fontSize="sm">
        {equipamento.id}  • {equipamento.name}

      </Text>
     
    </Box>
  )
}

