import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import React from "react"

import { useNotify } from '../../hooks/useNotify';
import { Relatorio } from '../../services/relatorio';
import { Card } from './components/Card';
import { LoadMore } from '../../components/LoadMore';
import { FullPageLoading } from '../../components/FullPageLoading';
import { PageConnectionProblem } from '../../components/PageConnectionProblem';
import { useRelatoriosEmAberto } from '../../hooks/useRelatoriosEmAberto';
import { db } from '../../store';
import { items } from "../../store/items"
import { useAuth } from '../../context/AuthContext';

export function ImportarRelatorio() {
  const {  user } = useAuth()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [visibleLoadMore, setVisibleLoadMore] = useState(true)
  const [relatorios, setRelatorios] = useState<Relatorio[]>([])
  const { data, isFetching, isError } = useRelatoriosEmAberto({ page, pageSize: 8 });
  const [loading, setLoading] = useState(false)
 
  const localRelatorios = useLiveQuery(async() => {
    const arr = await db.relatorio.toArray()
    return arr.map(arr => arr.id)
  })


  useEffect(() => { 
    if (!data?.length) setVisibleLoadMore(false)
    if (data) setRelatorios([...relatorios, ...data])

  }, [data])


  async function handleImport(relatorio: Relatorio) {
    setLoading(true)
    const exist = await db.relatorio.where("id").equals(relatorio.id).toArray()
  
    if (exist.length) {
      setLoading(false)
      return useNotify({        
        type: "info",
        description: `Relatório já importado.`,
      })
    }
    relatorio.inspecao.forEach((inspecao, i) => {
  
      relatorio.inspecao[i] = {
        ...inspecao,

        item: items.map(item => {
          return {
            pergunta: item['PERGUNTA DO TECNICO'],
            inspecao_id: inspecao.id,
            referencia: item['REFERENCIA'],
           
            responsabilidade: null,
            resposta: null,
            descricao: null,
            acao: null,
            observacao: null,
            anexo: null
          }
        })
      }
    })
  
    await db.relatorio.add({
      ...relatorio,
      user_id: user!.id
    });
    setRelatorios(relatorios.filter(rel => rel.id !== relatorio.id))
    navigate("/relatorio/" + relatorio.id)
    setLoading(false)
  }
  

  if (loading) return <FullPageLoading />
  if (isError) return <PageConnectionProblem />

  return (
    <Box p={4} >
      {relatorios?.map((relatorio, i) => {
         return  <Card
          key={`import-${i}`}
          relatorio={relatorio}
          onClick={handleImport}
          bgColor={localRelatorios?.includes(relatorio.id) ? 'red.200' : 'white'}          
        />

      })}
     <LoadMore onClick={() => setPage(page + 1)} state={isFetching} />
    </Box>
  )
}


