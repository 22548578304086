import React, { useMemo } from "react"
import {
  Box,
  Image,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  Button,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { PageNotFound } from '../../components/PageNotFound';
import { db } from '../../store';
import dayjs from 'dayjs';
import { CardHero } from './components/CardHero';
import { FullPageLoading } from '../../components/FullPageLoading';
import { ProgressBar } from './components/ProgressBar';
import { useLiveQuery } from 'dexie-react-hooks';
import { FaCogs, FaEdit } from 'react-icons/fa';
import { config } from "../../config/config";
import { Relatorio as IRelatorio } from "../../services/relatorio";
import { Compress } from "../../utils";
import { useNotify } from "../../hooks/useNotify";

export function Relatorio() {

  const params = useParams()
  const relatorio_id = params?.relatorio_id

  const query  = useLiveQuery(async () => {
    if (!relatorio_id) return { relatorio: null, progress: 0 }
    const relatorio = await db.relatorio.get(relatorio_id)

    if (!relatorio) return { relatorio: null, progress: 0 }
   
    const totalItems = relatorio?.inspecao?.length * (relatorio?.inspecao?.[0]?.item?.length || 0)
    const totalFeitos = relatorio.inspecao.reduce((total, inspecao) => {

      total += inspecao.item?.reduce((accumulator, currently) => {
        accumulator += currently?.resposta ? 1 : 0
        return accumulator
      }, 0)!

      return total
    }, 0)

    return { relatorio, progress: Math.floor(totalFeitos / totalItems * 100) }
  }) as { relatorio: IRelatorio, progress: number }

  async function handleChangeHeroImage(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return useNotify({ icon: "error", description: "Falha ao trocar imagem." });
    }
    const hero = await Compress(file)
    await db.relatorio.update(query?.relatorio.id, { hero })
  }

  if (!relatorio_id) return <PageNotFound />
  if (!query?.relatorio) return <FullPageLoading />

  return (
    <Center px={3} py={6} flexDirection="column">

      <Box
        maxW={'445px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
      >
        <CardHero relatorio={query.relatorio!} handleChangeHeroImage={handleChangeHeroImage} />

        <Stack>
          <HStack justify="space-between">
            <Link to={`/relatorio/${query.relatorio.id}/editar`}>
              <FaEdit fontSize={20} />
            </Link>

            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}>
              RIA
            </Text>
            <ProgressBar progress={query.progress} />
          </HStack>


          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            fontFamily={'body'}>
            {query.relatorio?.name}
          </Heading>
          <Text color={'gray.500'}>
            <b>Equipamentos</b>: {query.relatorio?.inspecao?.map(inspecao => inspecao.equipamento_id).join(", ")}
          </Text>
        </Stack>

        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Link to={`/profile/${query.relatorio.user_id}`}>
            <Avatar src={`${config.s3bucket}/user-${query.relatorio?.user_id}`} />
          </Link>

          <Stack direction={'column'} spacing={0} fontSize={'sm'}>
            <Text fontWeight={600}>Técnico Responsável</Text>
            <Text color={'gray.500'}>
              {dayjs(query.relatorio?.createdAt).format('DD/MM/YYYY [as] HH:mm:ss')}
            </Text>
          </Stack>
        </Stack>

        <Link to={`/inspecao/${query.relatorio.id}/${query.relatorio.inspecao[0].id}/1`}>
          <Button colorScheme={"green"} mt={5} w={"100%"}>
            Inspecionar
          </Button>
        </Link>
      </Box>

    </Center>
  );
}