import React from "react"
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";

export function OkCadastroEquipamento() {
  const navigate = useNavigate()
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Ótimo!
      </Heading>
      <Text color={'gray.500'}>

        Seu equipamento foi registrado com sucesso!
      </Text>
      <Box mt={5}>
        <Button colorScheme={"green"} variant="outline" onClick={() => navigate("/admin/painel/equipamentos/criar/")}>
          Cadastrar novo
        </Button>
      </Box>
      <Box>

        <Button variant={"link"} colorScheme="blue" mt={3} onClick={() => navigate("/")}>
          Voltar para início
        </Button>
      </Box>
    </Box>
  );
}