import { Box, Container, Text, Flex, Skeleton, SkeletonCircle, Spinner } from "@chakra-ui/react";
import { useLiveQuery } from "dexie-react-hooks";
import { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useNav } from "../../context/NavigationContext";
import { Relatorio, Inspecao as IInspecao, Item } from "../../services/relatorio";
import { db } from "../../store";
import { items, modulos } from "../../store/items"
import { ProgressBar } from "../Relatorio/components/ProgressBar";
import { PerguntasSlider } from "./components/PerguntasSlider";
import { PickerCurrentlyEquipamento } from "./components/PickerCurrentlyEquipamento";
import { ModuloList, ModuloMap, RelatorioQuery } from "./types";
import React from "react"
import { sleep } from "../../services/api";
import { FaLock, FaLockOpen, FaUnlock } from "react-icons/fa";
import { useCallback } from "react";
import { useMemo } from "react";
import { FullPageLoading } from "../../components/FullPageLoading";
import { InspecaoLoading } from "./components/InspecaoLoading";



export function Inspecao() {

  const { relatorio_id, inspecao_id, modulo_id } = useParams()
  const [shouldReload, setShouldReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lock, setLock] = useState(true);


  function turnShouldReload() {
    setShouldReload(!shouldReload)
  }

  const query = useLiveQuery(async () => {
    setLoading(true)
    const relatorio = await db.relatorio.get(relatorio_id!)

    let totalItems = items.length * (relatorio?.inspecao?.length || 0)
    let totalItemsIndividual = items.length
    let totalFeitos = 0;
    let totalFeitosPorEquipamento = {} as { [key: string]: number }

    let inspecao: IInspecao = {} as IInspecao

    const equipamentos = relatorio?.inspecao?.map((thisInspecao) => {
      totalFeitosPorEquipamento[thisInspecao.equipamento_id] = 0

      if (thisInspecao.id === inspecao_id)
        inspecao = thisInspecao

      for (let i = 0; i < totalItems; i++) {
        totalFeitos += thisInspecao.item?.[i]?.resposta ? 1 : 0
        totalFeitosPorEquipamento[thisInspecao.equipamento_id] += thisInspecao.item?.[i]?.resposta ? 1 : 0
      }

      return {
        equipamento_id: thisInspecao?.equipamento_id,
        inspecao_id: thisInspecao.id
      }
    })
      .sort((a, b) => Number(a.equipamento_id) > Number(b.equipamento_id) ? 1 : -1)

    let porcentagemIndividual = {} as { [key: string]: number }

    Object.keys(totalFeitosPorEquipamento).forEach(equipamento_id => {
      porcentagemIndividual[equipamento_id] = Math.floor(totalFeitosPorEquipamento[equipamento_id] / totalItemsIndividual * 100)
    })

    const referencias = modulos[ModuloMap[modulo_id!]]
    const filterLockPerguntas = (item: Item) => (referencias.includes(item.referencia) && !item.resposta)
    const filterUnlockPerguntas = (item: Item) => (referencias.includes(item.referencia))

    const perguntas = lock ? inspecao.item?.filter(filterLockPerguntas) : inspecao.item?.filter(filterUnlockPerguntas)
   setLoading(false)
    return {
      perguntas,
      relatorio,
      inspecao,
      equipamentos,
      totalItems,
      totalFeitos,
      porcentagem: Math.floor(totalFeitos / totalItems * 100),
      porcentagemIndividual
    }
  }, [shouldReload, lock]) as unknown as RelatorioQuery


  const Comp = useMemo(() => <PerguntasSlider
    query={query}
    modulo={ModuloMap[modulo_id!]}
    modulo_id={modulo_id!}
    setLock={setLock}
    reload={turnShouldReload}
    lock={lock}
    referencias={modulos[ModuloMap[modulo_id!]]}
  />, [query])

  if (!query?.relatorio || !query?.inspecao)
    return <FullPageLoading />

  if (loading)
    return <InspecaoLoading lock={lock} />

  if (!ModuloList.includes(ModuloMap[modulo_id!]))
    return <>Módulo inválido. </>



  return (
    <>
      <PickerCurrentlyEquipamento
        query={query}
        modulo={ModuloMap[modulo_id!]}
        reload={turnShouldReload}
      />
      {Comp}
    </>

  )
}