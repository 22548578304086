import { User } from '../../context/AuthContext';
import { api } from './../api';


interface ResponseSignIn {
  access_token: string;
  refresh_token: string;
  payload: Partial<User>;
}
export function signIn({ email, password }: { email: string, password: string }) {
  return api.post<ResponseSignIn>(`/authenticate/login`, { email, password })
}



interface ResponseRefreshToken {
  access_token: string;
  refresh_token: string;
  payload: Partial<User>;
}

export async function refreshToken(refresh_token: string) {
  try {
    const { data } = await api.post<ResponseRefreshToken>(`/authenticate/refresh-token`, {
      refresh_token,
    });

    if (!data) throw new Error(`Failed to connect with api`);

    return data;
  } catch (error) {
    return null;
  }
}

export async function getMyProfile() {
  return api.get<User>(`/user/me`);
}