import React from "react"
import {
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue,
} from "@chakra-ui/react"

export function ProgressBar({progress}: {progress: number}) {


  return (
    <CircularProgress size={"40px"} value={progress} color={useColorModeValue('green.400', 'green.200')}>
      <CircularProgressLabel color={useColorModeValue("gray.700", "gray.200")}>{progress}%</CircularProgressLabel>
    </CircularProgress>

  )
}