import React from "react"
import {
  Flex,
  Text,
  Stack,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { FaWifi, FaCloudDownloadAlt } from "react-icons/fa"
import { Link } from 'react-router-dom';
export function AbrirModalRascunhos() {
  return (
    <Link to={`/importar-rascunhos`}>
      <Stack
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue("red.50", "red.900"), 
        }}
        direction={'row'} align={'center'}
      >
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg="red.100"
        >
          <Icon as={FaCloudDownloadAlt} color={'red.500'} w={5} h={5} />
        </Flex>
          <Flex justifyContent={"center"} alignItems="center">
            <Text fontWeight={600}>Backup dos rascunhos</Text>
            <Icon as={FaWifi} ml={1} color={"green.200"} />
          </Flex>
      </Stack>
    </Link>
  )
}