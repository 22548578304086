import { BuildQuery } from '../../utils';
import { api } from './../api';


export interface Item {
  referencia: string
  pergunta: string
  responsabilidade: string | null
  resposta: number | null
  descricao: string | null
  inspecao_id: string
  anexo: string | null
  acao: string | null
  observacao: string | null
}
export interface Inspecao {
  id: string;
  equipamento_id: string;
  relatorio_id: string;
  OS?: any;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;

  item?: Item[]
}

export interface Relatorio {
  id: string;
  user_id: string;
  name: string;
  status: boolean;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  finishedAt?: Date
  hero?: string
  inspecao: Inspecao[];
}
export interface Rascunhos {
  "id": string
  "relatorio_id": string
  "texto": string
  "createdBy": string
  "createdAt": string
  "updatedAt": string
}
export async function getRelatoriosEmAberto({ skip, take }: { skip: number, take: number }) {
  const { data } = await api.get<Relatorio[]>(`/relatorio/open` + BuildQuery({skip, take }));
  return data
}

export async function getRascunhos({ skip, take }: { skip: number, take: number }) {
  const { data } = await api.get<Rascunhos[]>(`/relatorio/rascunho/download` + BuildQuery({skip, take }));
  return data
}