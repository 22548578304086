import { Flex, Text, Menu, MenuButton, MenuList, MenuItem, Container, CircularProgress, CircularProgressLabel, Box, Spinner, useColorModeValue, } from "@chakra-ui/react";
import { ModuloMap, DecodeModulo, Modulos, PickerEquipamento } from "../types"
import { Link, useNavigate } from "react-router-dom"
import React from "react"
import { FaLock, FaLockOpen } from "react-icons/fa";



export const PickerCurrentlyEquipamento = (props: PickerEquipamento) => {
  const { query, modulo, reload } = props
  const navigate = useNavigate()
  const { equipamentos, inspecao, relatorio } = query

  if (!query?.relatorio?.id) {
    return <Spinner />
  }

  return (
    <Container mt="5">

      <Flex justify="space-between" w="full">
        <Menu>
          <MenuButton cursor={'pointer'} minW={0}>
            <Text fontSize={10} fontWeight={"bold"}>     {inspecao?.equipamento_id}</Text>
          </MenuButton>
          <MenuList py={0}>
            {equipamentos?.map((equipamento, i) => (

              <MenuItem
                onClick={() => {
                  navigate(`/inspecao/${relatorio.id}/${equipamento?.inspecao_id}/${DecodeModulo?.[modulo]}`)
                  reload()
                }}
                key={`equipamentos-m-${i}`}
              >
                {equipamento?.equipamento_id}
              </MenuItem>

            ))}

          </MenuList>
        </Menu>
        <Menu>
          <MenuButton cursor={'pointer'} minW={0}>
            <Text fontSize={10}>{modulo?.substring(0, 12)}</Text>
          </MenuButton>
          <MenuList py={0}>
            {Object.keys(ModuloMap).map((thisModulo, i) => {
              return (
                <MenuItem onClick={() => {
                  navigate(`/inspecao/${relatorio.id}/${inspecao.id}/${thisModulo}`)
                  reload()

                }} key={`modulos-m2-${i}`}>{ModuloMap[thisModulo!]}</MenuItem>

              )
            })}
          </MenuList>
        </Menu>
        <Flex gap={2} align="center">
          <CircularProgress size={"40px"} value={query.porcentagemIndividual[inspecao.equipamento_id]} color={useColorModeValue("blue.400", "green.500")}>
            <CircularProgressLabel color={useColorModeValue("blue.700","green.50")}>{query.porcentagemIndividual[inspecao.equipamento_id]}%</CircularProgressLabel>
          </CircularProgress>
          <CircularProgress size={"40px"} value={query.porcentagem} color={useColorModeValue('green.400', 'blue.400')}>
            <CircularProgressLabel color={useColorModeValue("green.700", "green.50")}>{query.porcentagem}%</CircularProgressLabel>
          </CircularProgress>


        </Flex>


      </Flex>

    </Container>

  )
}