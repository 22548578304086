import { Box, Text, Input, Checkbox, Flex, Button, Container } from "@chakra-ui/react";
import { useDebounce } from "../../../hooks/useDebounce";
import { api } from "../../../services/api";
import { Equipamento } from "../../../services/equipamentos";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function CadastrarRelatorio() {
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const navigate = useNavigate()
  const debounceSearch = useDebounce(search, 500)
  const [filtered, setFiltered] = useState<Equipamento[]>([])
  const [selected, setSelected] = useState<Equipamento[]>([])

  useEffect(() => {
    setSelected([])
    setLoading(true)
    api.get("/equipamento/find?q=" + debounceSearch)
      .then(response => response.data)
      .then(data => setFiltered(data))
      .finally(() => setLoading(false))

  }, [debounceSearch]);


  async function handleSubmit() {

    if (typeof selected?.[0]?.name !== "string") {
      return alert("Selecione pelo menos um equipamento")
    }

    const name =  selected?.[0]?.name 

    for (const equipamento of selected) {
      if (equipamento.name !== name)
        return alert("ERRO! Selecione equipamentos de um mesmo condominio para consolidar.")
    }

    const payload = {
      name,
      "equipamentos": selected.map(equipamento => ({ id: equipamento.id }))
    }

    await api.post("/relatorio", payload)
      .then(response => {
        if (response.status === 200) {
          navigate("/admin/painel/relatorios/success")
        }
      })
      .catch(error => {
        alert(JSON.stringify(error))
      })
  }

  return (
    <Container py={5} maxW={["100%", "100%", "1200px"]}>
      <Text mb={1} fontSize="xs">Buscar Equipamento</Text>
      <Input value={search} onChange={(e) => setSearch(e.target.value)} />
      <Box
        p={2}
        my={4}
        h="250px"
        overflowY={"auto"}
        border={"1px solid gray"}
        borderRadius={5}
      >
        {
          (search?.length && filtered.length === 0) && loading
            ? <Text>Nenhum resultado encontrado.</Text>
            : ""
        }

        {filtered?.map(elevador => {
          return (
            <Checkbox
              w="full"
              key={elevador.id}
              onChange={(event) => {
                let list = [...new Set([...selected])];
                if (event.target.checked) { list.push(elevador) }
                else { list = list.filter(eq => eq.id !== elevador.id) }
                setSelected(list.sort((a, b) => a.id > b.id ? 1 : -1));
              }}
            >
              <Flex w="full" justifyContent={"space-between"}>
                <Text w="100px" fontSize={12}>
                  {elevador.id}
                </Text>
                <Text mt="1" noOfLines={1} w="full" fontSize={10}>
                  {elevador.name}
                </Text>
              </Flex>
            </Checkbox>
          )
        })}
      </Box>

      <Box my={4}>
        <Text mb={1} fontSize="xs">Nome do relatorio</Text>
        <Input value={selected?.[0]?.name || ""} isReadOnly />
        <Box my="3">
          <Text color="red" fontSize={"sm"}>
            {selected?.map(eq => eq.id).join(", ")}
          </Text>

        </Box>
      </Box>
      <Checkbox ml={1} mb={3} onChange={(e) => setDisabled(!e.currentTarget.checked)}>
        Confirmo os elevadores listados acima
      </Checkbox>
      <Button
        disabled={disabled}
        w="full"
        variant="outline"
        colorScheme={"green"}
        py={2}
        px={4}
        onClick={handleSubmit}
      >
        Registrar
      </Button>
    </Container>
  )
}